.dashboard {
  width: 1100px;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 48px 0;

  @media #{$mobile} {
    width: 100%;
    padding: 32px 0;
  }

  .nutritionist-dashboard,
  .company-dashboard {
    margin-top: 48px;

    .nutritionist-navigation,
    .company-navigation {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 16px;

      @media #{$mobile} {
        flex-direction: column-reverse;
        margin: 0;
        padding: 0 16px;
        box-sizing: border-box;
      }

      .options {
        display: flex;
        flex-direction: row;
        color: $primary;

        @media #{$mobile} {
          margin-top: 24px;
        }

        span {
          display: block;
          cursor: pointer;
          user-select: none;
          padding: 16px 32px;
        }

        .selected {
          background-color: $white;
          border-radius: 10px 10px 0 0;
        }
      }

      .new-button {
        font-size: 14px;

        @media #{$mobile} {
          width: 100%;
        }
      }
    }

    .company-navigation {
      justify-content: flex-end;
      margin-bottom: 32px;
    }

    .dashboard-content {
      padding: 0;
      border-radius: 10px;

      @media #{$mobile} {
        margin: 0 16px;
      }

      .no-users {
        padding: 32px;
        font-size: 20px;

        .speech {
          width: 300px;
          color: $not-black;
        }
      }

      .company-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 32px 32px 0 32px !important;
        flex-wrap: wrap;
        font-size: 28px;
        font-weight: bold;

        .right {
          display: flex;
          flex-direction: row;
          align-items: center;

          .amount-left-speech {
            color: $grey;
            font-size: 16px;
            font-weight: normal;
            margin-right: 16px;
          }
        }
      }
      .no-pad {
        padding: 0px !important;
      }
      .filters {
        padding: 32px;

        .options {
          padding: 16px 0;
          display: flex;
          flex-direction: row;

          @media #{$mobile} {
            flex-direction: column;
          }

          .option {
            cursor: pointer;
            user-select: none;
            margin-right: 24px;
            padding: 12px;
            border: 1px solid $grey;
            border-radius: 4px;
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            @media #{$mobile} {
              width: 100%;
              margin: 0;
              box-sizing: border-box;
              margin-top: 16px;
            }

            .text {
              margin-right: 8px;
            }
          }
        }
      }

      .balance-sheet {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 16px 0;

          .balance-navigation {
            width: 100%;
            box-sizing: border-box;
            padding: 16px 64px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media #{$mobile} {
              padding: 16px 24px;
            }

            .back,
            .further {
              font-size: 14px;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: $primary;
              cursor: pointer;
              user-select: none;
            }

            .back {
              span {
                margin-left: 8px;
              }
            }

            .further {
              span {
                margin-right: 8px;
              }
            }

            .actual-month {
              font-size: 20px;
              font-weight: bold;
            }
          }

          .actual-balance {
            width: 100%;
            padding: 24px 0;
            text-align: center;
            border-top: 1px solid $dark-background;
            border-bottom: 1px solid $dark-background;
          }

          .patients-listing {
            padding: 32px 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            box-sizing: border-box;

            @media #{$mobile} {
              padding: 32px 24px;
            }

            .patients-amount {
              font-weight: bold;
            }

            .list {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;
              margin-top: 24px;
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                padding: 0;
                text-indent: 0;
                list-style-type: 0;
                width: 25%;
                list-style: none;
                margin: 4px 0;
                color: $dark-secondary-text;

                @media #{$mobile} {
                  width: 50%;
                }
              }
            }
          }
        }

        .bottom {
          padding: 16px 64px;
          box-sizing: border-box;

          @media #{$mobile} {
            padding: 16px 24px;
          }

          .grey-container {
            padding: 16px;
            color: $grey;
          }
        }
      }
    }

    .special-border-radius {
      border-top-left-radius: 0;
    }

    .users-list {
      margin-top: 32px;

      .search-bar {
        padding: 32px;
      }

      .list {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;

        .user-row {
          padding: 24px 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $dark-background;

          @media #{$mobile} {
            padding: 16px 24px;
          }

          .left-chunk,
          .right-chunk {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 50%;
          }

          .left-chunk {
            @media #{$mobile} {
              flex-direction: column;
              align-items: flex-start;

              span {
                width: 100%;
              }
            }
          }

          .right-chunk {
            @media #{$mobile} {
              flex-direction: row-reverse;
              justify-content: flex-start;
            }
          }

          span {
            display: flex;
            width: 50%;
          }

          .state {
            user-select: none;
            padding: 12px;
            border: 1px solid $dark-background;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;

            @media #{$mobile} {
              padding: 0;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-left: 16px;
            }

            .state-text {
              width: 100%;
              display: flex;
              justify-content: center;

              @media #{$mobile} {
                display: none;
              }
            }
          }

          .actions {
            color: $primary;
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            margin-left: 32px;

            @media #{$mobile} {
              margin-left: 0;
              width: calc(100% - 45px);
              display: flex;
              justify-content: flex-end;
            }
          }

          &:last-child {
            border-bottom: 1px solid $dark-background;
          }
        }
      }
    }
  }

  .user-form {
    padding: 32px 64px;
    margin: 64px 0;

    @media #{$mobile} {
      padding: 16px;
      margin: 32px 16px;
    }

    .heading-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .back {
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $primary;

        p {
          margin-left: 12px;
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: bold;

      @media #{$mobile} {
        font-size: 16px;
        margin: 0;
      }
    }

    .checkboxes,
    .your-data {
      padding: 16px 0;
    }

    .top-separator,
    .bottom-separator {
      margin: 48px 0 16px 0;
      border-top: 1px solid $dark-background;
      width: 100%;

      @media #{$mobile} {
        margin: 16px 0;
      }
    }

    .bottom-separator {
      margin: 24px 0 36px 0;

      @media #{$mobile} {
        margin: 8px 0 16px 0;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
