.profile {
  width: 1100px;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 48px 0;

  @media #{$mobile} {
    width: 100%;
    padding: 32px 0;
  }
  .profile-dashboard {
    margin-top: 48px;
    .profile-navigation {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 16px;

      @media #{$mobile} {
        flex-direction: column-reverse;
        margin: 0;
        padding: 0 16px;
        box-sizing: border-box;
      }

      .options {
        display: flex;
        flex-direction: row;
        color: $primary;

        @media #{$mobile} {
          margin-top: 24px;
        }

        span {
          display: block;
          cursor: pointer;
          user-select: none;
          padding: 16px 32px;
        }

        .selected {
          background-color: $white;
          border-radius: 10px 10px 0 0;
        }
      }

      .new-button {
        font-size: 14px;

        @media #{$mobile} {
          width: 100%;
        }
      }
    }
    .profile-form-data {
      padding: 0 200px 50px 50px;
      @media #{$mobile} {
        padding: 5px;
      }

      .title {
        padding-top: 1em;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .subtitle {
        margin-block-start: 0;
        padding-top: 1em;
        color: $dark-secondary-text;
      }

      .buttons {
        margin-top: 48px;
        display: flex;
        flex-direction: row;

        @media #{$mobile} {
          margin-bottom: 16px;
          width: 100%;
          flex-direction: column;
        }
      }
    }

    .dashboard-content {
      padding: 0;
      border-radius: 10px;

      @media #{$mobile} {
        padding: 0 16px;
      }
    }
    .special-border-radius {
      border-top-left-radius: 0;
    }
  }
}

//Business profile styles:
.profile-business-content {
  padding: 50px;
  @media #{$mobile} {
    padding: 5px;
  }
  .profile-business-header {
    border-radius: 15px;
    height: 140px;
    background-color: $dark-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    overflow: hidden;

    @media #{$mobile} {
      height: 250px;
      flex-direction: column;
    }

    p {
      margin-block-end: 0;
      margin-block-start: 0;
    }
    .header-content {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding: 50px;
      @media #{$mobile} {
        width: 100%;
        padding: 30px;
        flex-direction: column;
      }
      .profile-kit-details {
        @media #{$mobile} {
          padding: 0px 30px;
        }
        .profile-kit-remaining {
          display: flex;
          font-size: 28px;
          font-weight: 500;
          align-items: center;
          justify-content: space-between;
          .subtitle {
            opacity: 0.5;
            font-size: 14px;
          }
        }
        .profile-kit-description {
          font-size: 24px;
        }
      }
      .profile-kit-cost {
        font-size: 42px;
        font-weight: bold;
        text-align: right;
        @media #{$mobile} {
          text-align: left;
          padding: 0px 30px;
        }
      }
    }
    .img-div {
      height: 100%;
      width: 285px;
      background-image: url("../assets/tile-profile-businesskits.png");
      background-size: 100% auto;
      padding: 0;
      margin: 0;
      @media #{$mobile} {
        background-image: none;
      }
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .profile-business-plan-options {
    display: flex;
    justify-content: space-around;
    @media #{$mobile} {
      flex-direction: column;
    }
  }
}

.business-plan-container {
  width: 282px;
  height: 419px;
  border-radius: 15px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media #{$mobile} {
    width: 100%;
    margin-bottom: 30px;
  }

  .business-plan-color {
    width: 100%;
    height: 14px;
  }

  .business-plan-content {
    padding: 10px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $greyish-brown;
    flex: 1;

    p {
      margin-block-end: 0;
      margin-block-start: 0;
    }

    .business-plan-kits {
      font-size: 24px;
      font-weight: 800;
    }
    .business-plan-name {
      font-size: 16px;
      font-weight: 500;
    }
    .business-plan-total {
      display: flex;
      flex-direction: column;
      @media #{$mobile} {
        flex-direction: row;
        align-items: center;
      }
      .business-plan-total-label {
        font-size: 12px;
        font-family: Helvetica;
        font-size: 12px;
        opacity: 0.5;
        color: $dark-secondary-text;
        padding-bottom: 1em;
        @media #{$mobile} {
          padding-bottom: 0;
          padding-right: 15px;
        }
      }
      .business-plan-cost {
        font-size: 42px;
        font-weight: bold;
      }
    }
    .business-plan-description {
      font-size: 16px;
      color: $dark-secondary-text;
    }

    hr {
      width: 100%;
      border: solid 1px $dark-secondary-text;
      opacity: 0.3;
    }
  }
}
.business-plan-container:nth-child(odd) {
  .business-plan-color {
    background-color: $seagreen;
  }
}
.business-plan-container:nth-child(even) {
  .business-plan-color {
    background-color: $pink;
  }
}
.business-plan-container:nth-child(3n) {
  .business-plan-color {
    background-color: $pastel-primary;
  }
}
