.dashboard {
  width: 1100px;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 48px 0;

  @media #{$mobile} {
    width: 100%;
    padding: 32px 0;
  }

  .user-dashboard {
    padding-bottom: 40px;
    .kit-coordination,
    .kit-pickup {
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
          font-size: 18px;
        }
      }
    }
    .loading-container {
      display: flex;
      height: 50vh;
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    .kit-confirmation {
      flex: 1;
      @media #{$mobile} {
        .content-heading {
          min-height: 150px;
        }
      }
      .information-container {
        margin-top: 70px;
        min-height: 100px;
        color: $primary-text;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        .title {
          font-size: 24px;
        }
        .subtitle {
          font-size: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          //flex-wrap: wrap;
          padding: 0 40px;

          @media #{$mobile}{
            padding: 0 10px;
          }
          p {
            text-align: left;
            font-weight: bold;
          }
          .instructions {
            padding: 0 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media #{$mobile}{
              flex-direction: column;
              padding: 0;
            }

            .instructions--text {
             padding: 0 20px;

             @media #{$mobile}{
              padding: 0;
            }
            }
            .instructions--img {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 20px;
            }
          }

        }
        .link {
          color: $primary;
          text-decoration: none;
          font-size: 20px;
        }
        .info {
          font-size: 16px;
          color: $dark-secondary-text;
          margin-bottom: 20px;
        }

        @media #{$mobile} {
          padding: 0 16px;
          margin: 0;
          box-shadow: none;
        }
      }
    }

    .user-dashboard-content {
      margin: 64px 0 32px 0;
      padding: 72px 32px 32px 32px;
      border-radius: 8px;
      position: relative;

      @media #{$mobile} {
        padding: 0 16px;
        margin: 0;
        box-shadow: none;
      }

      .content-heading {
        position: absolute;
        top: -24px;
        left: 24px;
        min-height: 100px;
        width: calc(100% - 148px);
        padding: 32px 48px;
        background-color: $dark-primary;
        border-radius: 24px;
        color: white;

        @media #{$mobile} {
          box-sizing: border-box;
          position: static;
          width: 100%;
          padding: 16px 32px;
          font-size: 14px;
        }

        p {
          margin: 0;
        }

        .title {
          font-size: 20px;
          margin-bottom: 15px;
        }

        .speech {
          font-size: 20px;

          @media #{$mobile} {
            font-size: 18px;
          }
        }
      }
    }

    .date-and-address {
      margin: 80px 0 16px 0;
      padding: 0;
      width: 100%;
      box-sizing: border-box;

      @media #{$mobile} {
        box-sizing: border-box;
        width: 100%;
        margin-top: 0;
      }

      .date,
      .address {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 16px;

        img {
          height: 60px;
        }

        .right-chunk {
          margin-left: 32px;
          flex-grow: 1;

          .title {
            margin: 8px 0;
            font-size: 20px;
            font-weight: bold;
          }

          .content {
            .options {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              .option-container {
                width: 33.333%;
                box-sizing: border-box;
                padding: 0 16px;
                display: flex;
                align-items: center;

                .option {
                  width: 100%;
                  cursor: pointer;
                  user-select: none;
                  font-size: 16px;
                  color: $grey;
                  border: 1px solid $grey;
                  margin: 8px 0;
                  padding: 8px 16px;
                  box-sizing: border-box;
                  border-radius: 6px;
                  text-align: center;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  -webkit-transition: all 0.3s ease-in-out;
                  transition: all 0.3s ease-in-out;
                }

                .selected-option {
                  color: $white;
                  background-color: $primary;

                  .check-icon {
                    margin-left: 8px;
                  }
                }

                &:nth-child(3n + 0) {
                  padding-right: 0;
                }

                &:nth-child(3n + 1) {
                  padding-left: 0;
                }

                &:nth-child(3n + 2) {
                  padding: 0 8px;
                }

                .add-icon {
                  margin-right: 8px;
                }
                @media #{$mobile} {
                  width: 100%;
                  padding: 0!important;
                }
              }
            }

            .other-option {
              padding: 16px 0;
              font-size: 18px;
              color: $grey;

              .speech {
                margin-right: 8px;
              }
            }
          }

          .coordinated-option {
            color: $not-black;
            font-size: 16px;

            .primary-link {
              margin: 0 16px;
            }

            button {
              padding: 4px 48px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .shipping-selection {
      margin: 96px 0 16px 48px;
      padding: 0;
      width: 100%;
      box-sizing: border-box;

      @media #{$mobile} {
        box-sizing: border-box;
        width: 100%;
        margin-top: 0;
        margin-left: 0;
      }
    }

    .results-container {
      margin: 96px 0 16px 0;
      padding: 0;
      width: 100%;
      height: 300px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      .tests-in-lab,
      .results-sent {
        color: $grey;

        .title {
          font-size: 24px;
          color: $not-black;
          margin: 8px 0;
        }

        p,
        span {
          text-align: center;
        }
      }
    }
  }
}
