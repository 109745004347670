.header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media #{$mobile} {
    padding: 0 16px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .logo {
    height: 40px;
    width: auto;
    cursor: pointer;
    user-select: none;
    order: 3;

    @media #{$mobile} {
      height: 30px;
      order: 1;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$mobile} {
      order: 2;
    }

    .variable-data {
      margin-right: 32px;
      display: flex;
      align-items: center;

      @media #{$mobile} {
        margin-right: 16px;
        order: 2;
      }

      .nutritionist-id {
        font-size: 20px;
        font-weight: bold;

        @media #{$mobile} {
          font-size: 16px;
        }
      }

      .business-image {
        height: 40px;
        width: auto;

        @media #{$mobile} {
          height: 30px;
          order: 1;
        }
      }
    }

    .profile-header {
      color: $primary;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      user-select: none;

      @media #{$mobile} {
        order: 2;
      }
      .image {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        background-color: $primary;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$mobile} {
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
      }

      span {
        font-weight: bold;
        font-size: 18px;

        @media #{$mobile} {
          display: none;
        }
      }
    }
  }
}
