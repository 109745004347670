.form {
  width: 100%;

  .horizontal-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 65px;

    @media #{$mobile} {
      height: auto;
    }

    @media #{$mobile} {
      flex-direction: column;
    }

    .field:last-child {
      margin-left: 12px;

      @media #{$mobile} {
        margin-left: 0;
      }
    }

    .field:only-child {
      width: 50%;
      margin-left: 0;
      margin-right: 12px;
      @media #{$mobile} {
        width: 100%;
        margin: 0;
      }
    }
    .one-field-line:last-child {
      margin-left: 0;
    }
  }

  .checkbox-horizontal-line {
    margin-top: 32px;
  }
}
