$primary-text: #4f4f4f;
$light-secondary-text: #b9c5d1;
$dark-secondary-text: #707f8c;
$primary: #7c67e2;
$light-pastel-primary: #bbb3d2;
$pastel-primary: #897ea8;
$dark-pastel-primary: #71567c;
$success: #a5da7c;
$warning: #ffe7b3;
$light-background: #fbfbfb;
$dark-background: #eaeaf0;
$dark-primary: #856a8f;
$darker-primary: #493650;
$not-black: #545454;
$grey: #aaaaaa;
$light-grey: #fafafa;
$pink: #e49daa;
$white: #ffffff;
$seagreen: #85cad2;
$greyish-brown: #4a4a4a;
$vibrant-yellow: #fec351;
$vibrant-orange: #f86431;

$diag-green: #BFE586;
$diag-yellow: #FFD782;
$diag-red: #ECA1AC;

$filter-red: #f82f0b;
$filter-orange: #f88f0b;
$filter-yellow: #ffd782;
$filter-green: #86d285;

$filter-light-red: rgba(200, 0, 0, 0.2);
$filter-light-orange: rgba(248, 143, 11, 0.2);
$filter-light-yellow: rgba(255, 215, 130, 0.3);
$filter-light-green: rgba(134, 210, 133, 0.3);

$mobile: "only screen and (max-width: 768px)";
$desktop: "only screen and (min-width: 769px)";

:export {
  primaryText: $primary-text;
  lightSecondaryText: $light-secondary-text;
  darkSecondaryText: $dark-secondary-text;
  primary: $primary;
  lightPastelPrimary: $light-pastel-primary;
  pastelPrimary: $pastel-primary;
  darkPastelPrimary: $dark-pastel-primary;
  success: $success;
  warning: $warning;
  lightBackground: $light-background;
  darkBackground: $dark-background;
  darkPrimary: $dark-primary;
  darkerPrimary: $darker-primary;
  notBlack: $not-black;
  grey: $grey;
  lightGrey: $light-grey;
  pink: $pink;
  white: $white;
  seagreen: $seagreen;
  greyishBrown: $greyish-brown;
  vibrantYellow: $vibrant-yellow;
  vibrantOrange: $vibrant-orange;
  filterRed: $filter-red;
  filterOrange: $filter-orange;
  filterYellow: $filter-yellow;
  filterGreen: $filter-green;
  filterLightRed: $filter-light-red;
  filterLightOrange: $filter-light-orange;
  filterLightYellow: $filter-light-yellow;
  filterLightGreen: $filter-light-green;
}
