.landing-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  justify-content: space-between;

  @media #{$mobile} {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .card {
    max-width: 30%;
    background-color: white;
    border-radius: 14px;
    min-height: 380px;

    @media #{$mobile} {
      min-height: 0;
    }

    .img-container {
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 2em;

      img {
        width: 60%;
      }
    }

    .title,
    p {
      text-align: center;
    }

    .title {
      color: $primary;
      font-size: 28px;
    }

    p {
      padding: 0 1em;
      color: $dark-secondary-text;
      font-size: 16px;
      line-height: 1.4em;
    }

    @media #{$mobile} {
      flex: 1;
      min-width: 95%;
      margin: 0.5em;

      .card-content {
        display: flex;
        flex-direction: row;
      }

      .img-container {
        flex: 1;

        img {
          width: 100%;
        }
      }

      .text {
        flex: 3;

        h3 {
          display: flex;
          height: auto;
          justify-content: center;
        }
      }
    }
  }
}
.result-card {
  border-radius: 5px 5px 0 0;
  padding: 2em;
  width: 100%;
  border-top: 12px solid $dark-primary;
  padding-bottom: 15px;
  box-sizing: border-box;

  @media #{$mobile} {
    padding: 10px;
  }

  h1 {
    font-weight: bold;
    color: $primary-text;
    @media #{$mobile} {
      font-size: 24px;
    }
  }

  p {
    color: $dark-secondary-text;
  }

  .agenda, .meals {
    padding: 10px;

    .week {
      width: 100%;
      //padding: 5px 30px;
      color: white;
      font-size: 1.3em;
      cursor: pointer;
      /* border-top: 1px solid white;
      border-bottom: 1px solid white; */
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  
      .week-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #ddd;
        border-bottom: 1px solid #fff;
        border-radius: 15px;

        div {
          width: 80%;
        }
        div:last-child{
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          font-size: 1.8em;
          color: $primary;
        }
        .toggle-icon {
          transition: transform 0.3s;
        }

        p {
          padding-left: 30px;
          font-weight: bold;
          line-height: 120%;

          &:first-child {
            color: $primary;
          }
          
          
        }
      }
  
      &.expanded {
        border-bottom: 1px solid #fff;
        background-color: lightcoral;
        border-radius: 16px;
        padding-bottom: 20px;

        .toggle-icon {
          transform: rotate(180deg);
        }
      }
    }
    .mealsList {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      

    .meal {
      margin: 10px;
      padding: 20px;
      background-color: white;
      //border: 1px solid #ccc;
      border-radius: 12px;
      width: 275px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $dark-primary;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      img {
        width: 100%;
        max-height: 200px;
        object-fit:fill;
        border-radius: 8px;
      }

      h3 {
        margin: 10px 0;
        font-size: 1em;
        text-align: center;
        line-height: 130%;
       
      }

      p {
        margin: 5px 0;
        font-size: 0.8em;
        line-height: 110%;
      }
    }
    }

  }
}

.results-pdf {
  display: flex;
  width: 100%;

  margin-top: 4em;

  justify-content: flex-end;

  @media #{$mobile} {
    justify-content: center;
    padding: 0;
  }

  a {
    margin-right: 10px;
    color: #71567c;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    font-weight: bold;
  }
}

.results-container {
  align-items: flex-start;
  margin-top: 0.2em;
  display: flex;
  flex-direction: column;

  @media #{$mobile} {
    padding: 1em;
    margin-top: 0;
  }

  .top-border {
    border-top: 1px solid $grey;
    padding-top: 2em;
  }

  .tabs {
    //flex: 1;
    display: flex;
    

    .tab-button {
      margin: 0.5em;
      border-radius: 5px;
      position: relative;
      background-color: #f4f4f4;
      
      &:last-child {
       

        svg {
          padding-right: 20px;
          padding-left: 25px;
        }
        span {
          padding-right: 30px;
          padding-left: 15px;
        }
      }

      @media #{$mobile} {
        background-color: transparent;
        box-shadow: none;
      }
      button {
        padding: 1em 0;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

        span {
          flex: 2;
          font-size: 18px;
          text-align: left;
          color: $dark-secondary-text;
        }

        svg {
          flex: 1;
        }
      }

      .selected {
        color: $primary;
        background-color: white;
        box-shadow: 0 0 12px 8px #f9f9f9;
        height: 100%;

        @media #{$mobile} {
          background-color: transparent;
          box-shadow: none;
        }

        svg {
          padding-left: 15px;

          @media #{$mobile} {
            padding-left: 0;
          }
        }

        &:after {
          content: "";
          height: 40%;
          width: 3px;
          position: absolute;
          left: 0;
          top: 30%;
          background-color: $primary;
        }
      }
    }

    @media #{$mobile} {
      flex-direction: row;
      display: flex;
      flex: 0;

      .label {
        display: none;
      }

      .selected .label {
        display: inline;
      }

      .tab-button .selected {
        padding: 0.5em;
      }
    }
  }

  .tab-content {
    flex: 3;
    display: flex;
    max-height: 70vh;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.5em;
  }

  .welcome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      padding: 2em;
    }

    .info {
      flex: 1;
      color: $primary-text;

      h2 {
        font-weight: normal;
        font-size: 32px;
        height: auto;
      }

      p {
        font-size: 14px;
      }
    }

    .images {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          width: 50%;
          z-index: 1;
        }

        &::after {
          content: "";
          height: 80px;
          width: 200px;
          border-radius: 100px;
          background-color: $dark-primary;
          z-index: 0;
          position: absolute;
        }

        &::before {
          content: "";
          height: 50px;
          width: 130px;
          border-radius: 100px;
          background-color: $dark-primary;
          z-index: 0;
          position: absolute;
          transform: translate(-60px, -40px);
          box-shadow: 120px 90px $dark-primary;
        }
      }

      .button-link {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @media #{$mobile} {
      flex-direction: column-reverse;

      div {
        padding: 5px;
      }

      .info {
        h2 {
          font-size: 24px;
          margin-top: 2em;
        }
      }

      img {
        max-width: 100px;
      }

      .icon {
        margin-top: 2em;
      }
    }
  }
}

.diagnostic-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid $grey;
  padding-top: 2em;
  &.metabolism {
    flex-direction: column;
  }

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  h3 {
    color: $dark-secondary-text;
  }
  h4 {
    height: auto;
    font-weight: 100;
    font-size: 18px;
    line-height: 1em;
    color: $primary-text;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: $dark-secondary-text;
    text-transform: uppercase;
    font-weight: bold;

    &.description {
      text-transform: none;
      font-weight: normal;
      padding-top: 1em;
      padding-right: 1em;
      line-height: 1.3em;
    }
  }

  .diagnostic {
    margin-bottom: 0.8em;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden; 
    transition: box-shadow 0.3s ease;
    width: 100%;
  
    &:hover {
      box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    }
  }
  
  .diagnostic-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 15px;
    background-color: #f9f9f9; 
  
    h2 {
      margin: 0;
      font-size: 1.2rem; 
      flex: 1;
      
      @media #{$mobile} {
        min-width: 45%;
        font-size: 0.9rem;
        padding: 0;
        
      }
    }
  }
  
  .circles-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px; 
  
    .circle {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin: 0 5px; 
      &.low {
        background-color: #558b2f; // Verde
      }
  
      &.medium {
        background-color: #ffeb3b; // Amarillo
      }
  
      &.high {
        background-color: #d32f2f; // Rojo
      }

      @media #{$mobile} {
        width: 20px;
        height: 20px;
      }
    }

    .result-text {
      width: 280px;
      padding-left: 25px;

      @media #{$mobile} {
        font-size: 0.8em;
        width: 100px;
        padding-left: 10px;
      }
    }
  }
  
  .arrow {
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; 

    
  
    &.open {
      transform: rotate(180deg);
    }
  }
  
  .description {
    max-height: 0;
    overflow:auto;
    overflow-x: hidden;
    //touch-action: none;
    transition: max-height 0.5s ease;
    padding: 0 15px 0;
    color: #666; 
    
    h3 {
      color: $dark-primary;
      font-weight: bold;
      padding-bottom: 5px;
      font-size: 2.4em;

      @media #{$mobile} {
        font-size: 1.8em;
      }
    }

    &.show {
      padding: 15px;
      height: auto;
      max-height: 1200px; 
    }


    .description-container {

      .general-info, .result, 
      .result-explain, .recommendations,
      .bibliography { 
        font-size: 1em;
        padding-bottom: 15px;
        color: #666;

        @media #{$mobile} {
          font-size: 0.9em;
        }
      }

      .general-info {

      }
      .result {
        
      
      }
      .result-explain {
        display: flex;
        flex-direction: column;

        .res-exp-text-circles {
            display: flex;
            width: 100%;

            .title-circles {
              display: flex;
              flex-direction: row;
              width: 100%;
              justify-content: space-between;
              
              @media #{$mobile} {
                flex-direction: column;
                
              }
        
              .title {
                display: flex;
                align-items: center;

                @media #{$mobile} {
                  order: 2;
                  
                }
              }
              .circles-text-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 20px;
                min-width: 30%;

                @media #{$mobile} {
                  order: 1;
                  padding: 20px 0;
                }
                
        
                .circles-container {
                  
                  
                  
                  .circle {
                    width: 50px;
                    height: 50px ;   
                    
                    &.low, &.medium, &.high {
                      width: 35px;
                      height: 35px;
                    
                      
                    }
                    
                  }
          
                  .active {
                    width: 70px !important;
                    height: 70px !important;
                    
                  }
                
                }
                .circles-text {
                  font-size: 1.2em;
                  padding-top: 10px;
                  font-weight: bold;
                  text-align: center;
                  
                  @media #{$mobile} {
                    
                    
                  }
                
                }
              }
            }
          
        }

      }
      .recommendations {

      }
      .bibliography {
        font-size:0.89em;

        @media #{$mobile} {
          font-size: 0.8em;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .diagnostic-header {
      flex-wrap: wrap;
      h2 {
        padding: 10px 0; 
      }
    }
  }

  /* .diagnostic {
    width:100%;
    margin-bottom: 1em;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
    box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  }
  
  .diagnostic h4 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }
  
  .diagnostic h4:after {
    content: '⌄';
    transition: transform 0.3s ease;
  }
  
  .diagnostic.open h4:after {
    transform: rotate(180deg);
  }
  
  .diagnostic .circles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
  
  .diagnostic .circles > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .diagnostic .circles div div {
    border-radius: 50%;
    border: 3px solid transparent;
    width: 1em;
    height: 1em;
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease;
  }
  
  .diagnostic .circles div div.low {
    border-color: $diag-green;
    color:$diag-green;
  }
  
  .diagnostic .circles div div.medium {
    border-color: $diag-yellow;
    color:$diag-yellow;
  }
  
  .diagnostic .circles div div.high {
    border-color: $diag-red;
    color:$diag-red;
  }
  
  .diagnostic .circles div div.active {
    width: 2.5em;
    height: 2.5em;
    background-color: currentColor;
  }
  
  .diagnostic .description, .diagnostic .circles {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .diagnostic.open .description,
  .diagnostic.open .circles {
    max-height: 1000px; 
  }
  
  /*@media (max-width: 768px) {
    .diagnostic {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .diagnostic {
      width: 100%;
    }
  
    .diagnostic .circles {
      padding-right: 0;
    }
  } */
  /* .diagnostic {
    // background-color: red;
    width: 33%;
    margin-bottom: 1em;

    .circles {
      display: flex;
      width: 100%;
      justify-content: space-between;
      box-sizing: border-box;
      padding-right: 4em;
      align-items: center;
      margin-top: 1em;

      & > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      div div {
        border-radius: 500px;
        border: 3px solid;
        width: 1em;
        height: 1em;
        background-color: transparent;

        &.low {
          border-color: $diag-green;
          color: $diag-green;
        }

        &.medium {
          border-color: $diag-yellow;
          color: $diag-yellow;
        }

        &.high {
          border-color: $diag-red;
          color: $diag-red;
        }

        &.active {
          width: 2.5em;
          height: 2.5em;
          background-color: currentColor;
        }
      }
    }

    @media #{$mobile} {
      width: 50%;

      .circles {
        padding-right: 1em;
      }
    }
  } */
}

.foods-container {
  color: $primary-text;
  height: 100%;

  .loading {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .searchbar-container {
    display: flex;
    justify-content: center;

    margin-bottom: 1em;

    .filter-mobile {
      display: none;
    }

    .searchbar {
      width: 99%;
      border-radius: 20px;
      border: 1px solid $light-secondary-text;
      background-color: $light-background;
      padding: 1em;
      margin: 1em 0;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }
  }

  .layout {
    display: flex;
    flex-direction: row;
    padding-bottom: 100px;
    position: relative;

    .count {
      position: absolute;
      top: 0;
      right: 1em;
    }

    .filters {
      margin: 1em;
      min-height: 0;
      height: 100%;
      max-width: 20%;
      box-sizing: border-box;
      flex-direction: column;

      .filter-checkbox {
        padding: 0.1em 0.3em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid $light-secondary-text;

        &:first-of-type {
          border-top: 1px solid $light-secondary-text;
        }

        label {
          cursor: pointer;
          color: $dark-secondary-text;
          overflow: hidden;
        }
      }

      .title {
        padding: 0.5em;
        font-size: 18px;
      }

      .clear {
        padding: 0.3em 1em;
        margin: 0.5em;
      }
    }

    .grid {
      margin-top: 2em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 3;
      height: 100%;

      @media #{$mobile} {
        .filters,
        .searchbar-container {
          display: none;
        }
      }

      .food-item {
        width: calc(25% - 1em);
        display: flex;
        flex-direction: column;
        margin-right: 1em;
        margin-bottom: 1em;
        align-items: flex-start;
        color: $dark-secondary-text;

        @media #{$mobile} {
          width: 100%;
          flex-direction: row;
          margin: 0.5em;
          margin-bottom: 0;
        }

        h4 {
          height: auto;
          font-weight: normal;
          font-size: 18px;
          color: $primary-text;
        }

        span {
          display: block;
          font-size: 14px;
        }

        .data {
          padding: 0.5em;
        }

        .content {
          width: 100%;
        }

        .score-container {
          width: 100%;
          text-align: center;
          color: white;
          color: $dark-secondary-text;
          border: 1px solid;
          box-sizing: border-box;
          padding: 0.2em;

          &.type-0 {
            /* background-color: darken($diag-green, 30%);
            border-color: darken($diag-green, 30%); */
            background-color: darken(#33691e, 2%);
            border-color: darken(#33691e, 10%);
            color: white;
          }

          &.type-1 {
            background-color: darken(#8bc34a, 5%);
            border-color: darken(#8bc34a, 15%);
            color: white;
          }

          &.type-2 {
            background-color: darken(#ffeb3b,5%);
            border-color: darken(#ffeb3b, 15%);
            
          }
          &.type-3 {
            background-color: #d50000;
            border-color: darken(#d50000, 10%);
            color: white;
          }

          @media #{$mobile} {
            border-top-right-radius: 5px;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-height: 150px;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;

          @media #{$mobile} {
            border-top-right-radius: 0px;
            border-bottom-left-radius: 5px;
          }
        }
      }
    }

    @media #{$mobile} {
      .filters,
      .count {
        display: none;
      }

      .grid {
        margin-top: 0;
      }
    }
  }

  @media #{$mobile} {
    .layout {
      position: initial;

      &.open {
        max-height: 0;
        overflow: hidden;
      }
    }

    .searchbar-container {
      margin-bottom: 0;
      margin: 0 0.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-mobile {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        max-width: 30%;
        margin-left: 10px;
      }

      input {
        flex: 3;
        max-width: 70%;
        margin: 0;
      }
    }

    .filters {
      .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 2em;
      }

      &.open {
        display: block;
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        z-index: 1000;
        top: 0;
        margin: 0;
        padding: 0;
        height: 100vh;
        overflow: scroll;
      }
    }
  }
}

div.navigator-container {
  border-radius: 50px;
  padding: 0 0.2em;
  order: 2;
  @media #{$mobile} {
    margin-top: 20px;
    order: 3;
  }

  .end-user-navigator {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 5px;
    justify-content: space-between;
    align-items: center;

    li {
      margin: 2px;
      

      &.active button {
        background-color: $primary;
      }

      button {
        padding: 0.2em 0.6em;
        
        @media #{$mobile} {
          padding: 0.2em 0.4em;
          font-size: 0.8em ;
        }
      }

      &.active button span {
        color: white;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.tab-content {
  @media #{$mobile} {
    max-height: none;
  }
}

.dashboard {
  padding-bottom: 50px;
  height: 100%;
  padding-bottom: 20px;

  .agendaPadding {
    @media #{$mobile} {
      padding: 15px;
      text-align: center;
    }
  }
  .mealsPadding {
    padding-bottom: 40px;
  }
}
