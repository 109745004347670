.container {
  p {
    margin: 0;
  }

  .header-payment {
    position: absolute;
    right: 40%;
    color: $primary;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$mobile} {
      right: 10px;
      color: $light-grey;
    }
  }
  .logout {
    padding: 1em;
    cursor: pointer;
  }
  .gotomeals {
    cursor: pointer;
  }

  .user-payment-coupon {

    @media #{$mobile}{
      overflow: hidden;
    }

    .payment-right-content {
      
      @media #{$mobile}{
        
        
      }

      .MuiPaper-root {
        
        @media #{$mobile}{
          overflow: visible;
          z-index: 10000;
        }
      }

      .logo {

        @media #{$mobile} {
          padding: 20px 0px;  
        }
        
      }

      .purchase-card {


      }


    }

  }

  .coupon  {
    
    .MuiFormControl-root {
      width: 100% !important;
    }
    .form .horizontal-line .field:last-child {
      width: 100% !important;
    }
  }

  .finalPrice {
    display:flex;
    width:100%; 
    justify-content:space-around; 
    padding:0px 25px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 30px;
    
    
    @media #{$mobile} {
      padding: 0 !important;
      margin-top: 80px !important;
      //padding-top:150px;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .final-price-coupon {
      width:100%; 
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      align-items: center;
      justify-content:center;
      font-size:1.4rem;
      font-weight:bold;
      //color:#493650;
      color: $primary;

      @media #{$mobile} {
        padding-bottom: 20px;
        font-size: 1.5rem;
      }
    }

    .form {
      width:100% !important;
      padding-right: 0 20px;

      .your-data {

        .title {
          padding-top: 30px;
        }
      }
      .buttons {
        padding:0px 25px;

        @media #{$mobile} {
          margin: 25px 0px;
          padding: 0 !important;
          width: 100%;
        }
      }
    }
    
  }
  
  
}
