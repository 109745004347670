

.menu-semanal {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    
    @media #{$mobile}{
        
        padding-bottom: 40px;    
    }
    //padding: 20px 10px;
    
    
   /*  <div className="menu-semanal">
    <div className="logo-week-cart-header">
        <div className="meals-logo-header">LOGO</div>
        <div className="week-cart">
            <div className="tabs">{renderWeekTabs()}</div>  
            {renderCart()}
        </div>
    </div>
  <div className="platos-semana">{renderMeals()}</div> */

    .logo-week-cart-header {
        display: flex;
        position: relative;
        background-color: $dark-background;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: fixed;

        .meals-logo-header {
            display: flex;
            
            align-items: center;
            width: 100%;
            background-color: white;
            height: 60px;
            //padding: 30px 0px;
            box-sizing: border-box;
            justify-content: space-around;
            
            //border-bottom: 1px solid $dark-primary;

            @media #{$mobile}{
                justify-content: space-between;
                padding: 40px 30px;    
            }
            img {
              cursor: pointer;
              z-index: 1000;
            }

            .cart-icon-container {
                display: flex;
                align-items: center;
                cursor: pointer;
                /* Agregamos altura mínima para que se muestre el contenedor */
                min-height: 40px;
                z-index: 1000;
               
                svg {
                  font-size: 24px;
                  margin-right: 5px;
                  padding-top: 10px;
                }
          
                .rel-div {
                  position: relative;
                
                  span {
                    position: absolute;
                    top: -20px;
                    right: -9px;
                    background-color: #7c67e2;
                    border-radius: 100%;
                    width: 24px;
                    height: 24px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.8em;
                    font-weight: normal;
                  }
                }
                .profile-div {
                  padding-left: 35px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #7c67e2;
                    color: white;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    font-family: "Mukta",sans-serif;

                  }
                  span {
                    padding-left: 10px;
                    color: #7c67e2;
                  }
                }
          
              }
            
        }
        .week-cart {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            

            .tabs {
                display: flex;
                
                .week-tab {
                  cursor: pointer;
                  padding: 15px 18px;
                  //border: 1px solid #ccc;
                  margin-right: 8px;
                  font-weight: bold;
                  color: #333;
               

                  @media #{$mobile}{
                    padding: 8px;
                    font-size: 0.9em;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-weight: normal;
                  }

                  &.active {
                    background-color: $primary;
                    color: #fff;
                  }
                 
                }
              }

              .cart {
                position: fixed;
                box-sizing: border-box;
                top: 60px;
                right: 0px;
                background-color: #f9f9f9;
                border: 1px solid #ccc;
                padding: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                z-index:2;
                overflow-y: auto;
                max-height: 80vh;
                min-width: 450px;
                
                @media #{$mobile} {
                    top: 80px;
                    right: auto;
                    max-height: 70vh;
                    min-width: 90%;
                }
                
                &.open {
                  transform: translateY(0);
                  opacity: 1;
                  display: flex;
                }
            
                &.closed {
                  transform: translateY(-100%);
                  opacity: 0;
                  display: none;
                }

                h2 {
                  margin: 0 0 10px;
                  font-size: 18px;
                  width: 100%;
                  text-align: center;
                  border-bottom: 1px solid grey;
                }
                
                .cart-order-amount {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  border-top: 1px solid grey;

                  div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                  }
                  .total-meals {
                    padding-top: 10px;
                  }
                }

                .cart-icon-container {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  /* Agregamos altura mínima para que se muestre el contenedor */
                  min-height: 40px;
                  max-height: 100%;
                  
                   
                  @media #{$mobile}{
                    display: none !important;
                    
                }
                  svg {
                    font-size: 24px;
                    margin-right: 5px;
                  }
            
                  span {
                    font-size: 18px;
                  }
                }
            
                .cart-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  //margin-top: 10px;
                  overflow-y: auto;
                  -webkit-overflow-scrolling: touch;
                  padding: 10px 0;
            
            
                  ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
            
                    li {
                      margin-bottom: 5px;
                      color: $darker-primary;
                      font-weight: bold;
                      

                      ul {
                        padding: 20px;

                        li {
                            font-weight: normal;
                            list-style:disc;
                            color: $primary-text;
                        }
                      }
                    }
                  }
                }
                button {
                    margin-top: 10px;
                    background-color: $primary;
                    color: #fff;
                    width: 100%;
                    padding: 12px 35px;
                    border: none;
                    border-radius: 20px;
                    cursor: pointer;
                    transition: box-shadow 0.6s ease;
                    font-weight: bold;

                    @media #{$mobile}{
                      padding: 8px 16px;
                      width: 85%;
                     }

                    &:hover {
                        box-shadow: inset 0 0 50px #444;
                    }
                  }
              }
        }
        

          
    }
    .platos-semana-header {
      padding-top: 130px;
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; 
      background-color: lightyellow;
      
      h4 {
        text-align: center;
      }

      @media #{$mobile} {
        

        h1, h2, h3, h4 {
          width: 90%;
          text-align: center;
        }
        h1 {
          font-size: 1.4em;
          line-height: 1.3em;
          font-weight: bold;
          padding-bottom: 10px;
        }
        h2 {
          font-size: 0.75em;
        }
        h4 {
          font-size: 0.75em;
          
        }
      }

    }
    .platos-semana {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      //padding-top: 150px;
      padding-bottom: 60px;
      padding-top: 15px;

      @media #{$mobile}{
        grid-template-columns: repeat(2, 1fr);
        padding: 10px 12px 0px 12px;
        font-size: 0.7em;
      }
  
      .meal {
        text-align: center;
        width: 350px;
        border-radius: 18px;
        background-color: white;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px;
        align-items: center;

        @media #{$mobile}{
            width: auto;
            padding: 12px 5px;
           }
        img {
          max-width: 100%;
          height: auto;

          @media #{$mobile}{
            width: 100%;
            display: none;
           }

        }
  
        h3 {
          margin: 3px 0;
          height: auto;
          font-size: 1.1em;

          @media #{$mobile}{
            height: auto;
            font-size: 1.3em;
            margin: 0;
           }
        }
        .subt {
            height: 30px;
        }
        p {
            height: 30px;

            @media #{$mobile}{
                height: 20px;
               }
        }
        button {
          margin-top: 10px;
          background-color: $primary;
          color: #fff;
          width: 100%;
          padding: 12px 35px;
          border: none;
          font-weight: bold;
          cursor: pointer;
          border-radius: 20px;
          transition: box-shadow 0.3s ease;
          
          @media #{$mobile}{
            padding: 9px 16px;
            width: 85%;
            font-weight: normal;
           }

          &:hover {
            box-shadow: inset 0 0 50px #444;
          }
          
        }
        .day-selector {
            padding-bottom: 10px;
            display: flex;
            width: 80%;
            justify-content: space-around;
            padding: 5px 25px;
            background-color: lightgray;
            border-radius: 10px;

            @media #{$mobile}{
                padding-bottom: 0;
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: auto;
                padding: 8px 35px;
                
              }
        }
      }
    }
  
    .order-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 140px;
        padding-bottom: 30px;

        @media #{$mobile}{
          margin-top: 130px;
        }

        h2 {
          margin-bottom: 20px;
          font-size: 24px;
        }
    
        .form-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 15px;
    
          label {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
          }
    
          input {
            width: 300px;
            height: 40px;
            padding: 8px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 5px;
          }

          div {

            input {

              width: auto;
              height: auto;
              justify-content: flex-start;
              padding: 8px;
              font-size: 16px;
              border: 1px solid #ccc;
              border-radius: 5px;
            }
          }
        }
    
        button, button[type="submit"] {
          width: 100%;
          height: 40px;
          font-size: 1.1em;
          font-weight: bold;
          color: #fff;
          background-color: $primary;
          border: none;
          cursor: pointer;
          border-radius: 20px;
          transition: box-shadow 0.3s ease;
          margin: 5px 0px;
    
          &:hover {
            box-shadow: inset 0 0 50px #444;
          }
        }
        .cancel-order, .submit {
            background-color: #aaa;
        }
      }
  }
  .confirmation-message {
    padding-top:130px;
  }


//// SCSS CONSULTA NUTRICIONAL
.menu-semanal {

  .logo-week-cart-header{
    .consulta-cart {
      background-image:  url('https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-intro-banner_mel3yx.png'); 
      width:100%; 
      background-position-y: -350px;
      
      @media #{$mobile}{
        height: 300px;
        //background-position-y: -200px;
      }


      .btn-consulta-container {
        padding: 60px 0px; 
        display: flex; 
        justify-content: center; 
        width:100%;

        @media #{$mobile} {
          
          flex-direction: column;
          align-items: center;
          
        }

        .btn-consulta {
          display: flex;
          font-size:18px;
          padding:12px 50px;  
          margin: 0px 30px;

          @media #{$mobile} {
            margin-bottom: 15px;
          }

          &:first-child {
            //margin:0px 15px; 
            
            @media #{$mobile} {
              //margin-bottom: 15px;
            }
          }

          @media #{$mobile}{
            width: 90%;
            font-size: 1.1em;
            margin: 5px 0px;
            padding: 12px 40px;  
          }
        }
      }
      
    }
  }
    
  .container-consulta-content {
    margin:250px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media #{$mobile}{
      margin: 300px 0px 0px 0px;
    }


    .ccc-title {
      width:90%;
      text-align: center;
      display: flex; 
      justify-content:center;
      color:#6c63ff;
      height:auto;
      font-size: 1.3em;
      font-weight: bold;
      margin-top: 5px;

    }
  }

    .analysis-plus-container {
      display: flex;
      max-width: 900px;
      padding-top: 5px;

      @media #{$mobile}{
        flex-direction: column;
        align-items: center;
        width: 100%;
        //padding: 0px 10px;
      }

      //border: 1px solid #ccc;
      //padding: 20px;
      //border-radius: 8px;
      //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    
    .image-container {
      flex: 1;
      align-items: center;
      padding: 10px;
      margin-top:30px;

      @media #{$mobile}{
        width: 90%;
        justify-content: center;        
        margin-top: 5px;
      }
    }
    
    .image-container img {
      width: 90%;
      border-radius: 8px;

      @media #{$mobile}{
        
        width: 100%;
      }
      
    }
    
    .content-container {
      flex: 1;
      width: 100%;
      padding: 10px;

      @media #{$mobile}{
        width: 85%;
        
      }
    }
    
    .content-container h1 {
      font-size: 24px;
      margin-bottom: 10px;
      height: auto;
    }
    
    .content-container p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    
    .content-container ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 10px;
      font-weight: bold;
    }
    
    .content-container ul li {
      font-size: 16px;
      margin-bottom: 5px;
    }
    
    .example-report-link,
    .faq-link {
      display: block;
      margin-bottom: 10px;
      color: #0066cc;
      text-decoration: none;
    }
    
    /* .price-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      color: $primary;
      padding-top:20px;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .price-container div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

    }
    
    .price-container .price {
      font-size: 32px;
      margin-left: 5px;
      font-weight: 300;
    }

    .price-container .price2{
      font-size: 36px;
      font-weight: bold;
    } */


    .price-container {
      text-align: center;
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin: 20px auto;
      font-family: Arial, sans-serif;
    }
    
    .price-total {
      font-size: 16px;
      color: #555;
      margin: 0;
    }
    
    .price-amount {
      font-size: 32px !important;
      color: #6c63ff !important;
      font-weight: bold !important;
      margin: 10px 0;
    }
    
    .tax-included {
      font-size: 14px;
      color: #888;
      display: block;
      margin-top: 5px;
    }
    
    .installments {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      margin: 10px 0;
    }
    
    .security-note {
      font-size: 12px;
      color: #666;
      margin-top: 10px;
    }
    
    /* .checkout-button {
      background-color: #673ab7;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
    }
    
    .checkout-button:hover {
      background-color: #5e35b1;
    } */

}
#wallet_container #walletBrick_container{
  outline: none;
  box-shadow: none; /* Elimina el sombreado en algunos navegadores */
}
#wallet_container #walletBrick_container button {
  outline: none;
  box-shadow: none; /* Elimina el sombreado en algunos navegadores */
}
#wallet_container #walletBrick_container button:focus {
  outline: none;
  box-shadow: none;
}
#wallet_container #walletBrick_container button:hover {
  background-color: #0074D9; /* Cambia a tu color preferido */
  cursor: pointer;
}
#walletBrick_container {
  outline: none;
  box-shadow: none; 
}
#walletBrick_container button {
  outline: none;
  box-shadow: none; 
}
#walletBrick_container button:focus {
  outline: none;
  box-shadow: none;
}
#walletBrick_container button:hover {
  background-color: #0074D9; 
  cursor: pointer;
}
#walletBrick_container::selection {
  background-color: transparent;
  box-shadow: none;
  outline: none;
}

.pink-color {
  color:#6c63ff;
  font-weight: bold;
}