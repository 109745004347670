.welcome-screen-in-half {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  /*max-height: 100vh;*/
  height: 100%;
  color: $primary-text;

  .welcome-half-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 50%;
    align-items: flex-start;
    padding: 100px;
    padding-right: 20px;
    padding-top: 50px;
    box-sizing: border-box;

    @media #{$mobile} {
      width: 100%;
      padding: 16px;
    }

    @media only screen and (max-width: 1400px) {
      padding: 20px;
    }
  }

  .welcome-left-content {
    margin-left: 100px;

    @media #{$mobile} {
      margin-left: 0;
      margin: 16px;
    }

    .welcome-title {
      a {
        width: 80px;
      }

      @media #{$mobile} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 16px;
        .back {
          padding-top: 16px;
        }
      }
    }

    .logo {
      height: 40px;
      width: auto;
      cursor: pointer;

      @media #{$mobile} {
        height: 50px;
      }
    }

    .back {
      margin-top: 30px;
      font-size: 18px;
      color: $primary;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      text-decoration: none;
      display: block;

      @media #{$mobile} {
        margin-top: 0;
      }
    }

    .huge {
      font-size: 40px;

      @media #{$mobile} {
        font-size: 24px;
      }
    }
    h3 {
      font-size: 13px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      line-height: 22px;
      color: $not-black;

      @media #{$mobile} {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .pdouble {
      font-size: 15px;
      padding: 5px;
      padding-right: 50px;

      @media #{$mobile} {
        padding-right: 0;
      }
    }

    .button {
      margin-top: 10px;

      @media #{$mobile} {
        margin: 24px 0;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
    .link-mobile {
      text-decoration: none;

      justify-content: center;

      display: none;
      text-align: center;
      p {
        color: $pink !important;
        text-decoration: none !important;
      }
      @media #{$mobile} {
        display: flex;
      }
    }
  }

  .welcome-right-content {
    background-color: $dark-primary;
    color: $white;
    justify-content: space-between;
    display: flex;
    max-width: 46%;
    flex-direction: row-reverse;
    align-items: center;

    flex-grow: 1;
    padding: 0px;
    padding-right: 0px;
    padding-top: 0px;
    box-sizing: border-box;

    @media #{$mobile} {
      display: none;
    }
    img {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
    }

    a {
      color: $pink;
      text-decoration: none;

      p {
        padding-left: 80px;
        margin-block-start: 3em;
      }
    }

    h1 {
      padding-left: 80px;
      padding-right: 50px;
      line-height: 66px;
      font-size: 56px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal;
      max-width: 400px;
    }
  }
  .company-landing {
    display: flex;
    justify-content: baseline;
    align-items: flex-start;
    flex-direction: row;
    @media #{$mobile} {
      flex-direction: column;
    }

    .section {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1em;
      .image-wrapper {
        height: 60px;
      }
      img {
        width: 67px;
        &.oms,
        &.wef {
          width: 87px;
        }
      }
      h3 {
        text-align: center;
        padding: 1em 0;
      }
      p {
        text-align: center;
      }
    }
  }
}

.user-signup,
.company-signup {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;

  @media #{$mobile} {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .signup-left-content {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: flex-start;
    padding: 50px 200px;
    padding-bottom: 0;
    box-sizing: border-box;

    @media #{$mobile} {
      width: 100%;
      padding: 16px;
      margin-top: 16px;
    }

    .logo {
      height: 30px;
      width: auto;

      @media #{$mobile} {
        display: none;
      }
    }

    .stepper {
      margin-top: 10px;
      width: 100%;
      display: none;
    }
    .back {
      font-size: 18px;
      color: #7c67e2;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
      display: block;
    }

    .user-signup-content,
    .company-signup-content {
      margin: 12px 0;

      @media #{$mobile} {
        margin: 0;
        padding-top:40px;
      }

      .title {
        font-size: 20px;

        @media #{$mobile} {
          margin-bottom: 0;
        }
      }
    }

    .company-signup-content {
      .title {
        @media #{$mobile} {
          margin-top: 48px;
        }
      }
    }

    .buttons {
      margin: 48px 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;

      @media #{$mobile} {
        margin-bottom: 16px;
        width: 100%;
        flex-direction: column;
      }

      .outlined-button {
        padding: 8px 48px;
        font-size: 16px;
        margin-right: 15px;

        @media #{$mobile} {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }

      .big-button {
        font-size: 20px;
      }
    }

    .loading-payment,
    .checkout,
    .payment-result,
    .iframe-container,
    .loading-verification,
    .email-verified,
    .verification-failed {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-top: 10px;

      @media #{$mobile} {
        //margin-top: 54px;
      }

      .logo {
        height: 30px;
        cursor: pointer;

        @media #{$mobile} {
          display: block;
        }
      }

      p {
        margin-top: 32px;
        font-size: 24px;
        color: $not-black;
        width: 50%;
        text-align: center;

        @media #{$mobile} {
          width: 80%;
        }
      }

      iframe {
        //border: 3px solid $dark-background;
        border: none;
        border-radius: 16px;
        width: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
      }
    }

    .payment-result {
      align-items: center;

      h2 {
        font-size: 36px;
        margin: 16px 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 32px;
        text-align: center;
        width: 100%;
      }
    }

    .checkout {
      align-items: flex-start;

      form {
        width: 50%;

        @media #{$mobile} {
          width: 100%;
        }
      }

      .section {
        margin-bottom: 32px;

        @media #{$mobile} {
          margin-bottom: 16px;
        }
      }

      .subtitle {
        text-align: left;
        margin: 0;
        width: 100%;
      }

      .instrument-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $dark-background;
        padding-bottom: 16px;
        margin-top: 24px;
        color: $primary-text;

        img {
          height: 24px;
          width: auto;
          margin-right: 32px;
        }
      }

      .checkout-speech {
        margin: 0;
        font-size: 16px;
        text-align: left;
        width: 100%;
        color: $grey;
      }

      button {
        margin-top: 48px;

        @media #{$mobile} {
          margin-top: 24px;
        }
      }
    }
    .header {
      justify-content: space-between;
      display: flex;
      width: 100%;

      @media #{$mobile} {
        margin-top: 36px;
      }
    }
  }

  .signup-right-content {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: flex-start;
    padding: 100px;
    background-color: $dark-primary;
    color: white;
    box-sizing: content-box;
    margin: 0;
    background: url(../assets/landing-cat-mosaic.png) no-repeat;
    background-size: 100%;
    background-position-x: right;
    margin: -1px -1px;

    @media #{$mobile} {
      width: 100%;
      padding: 16px;
      height: 160px;
      background: $dark-primary;
    }

    .logo {
      display: none;

      @media #{$mobile} {
        display: block;
        height: 40px;
        margin-top: 16px;
        padding-left: 14px;
        padding-top:15px;
        width: auto;
        margin-bottom: 10px;
      }
    }src/styles/_payment.scss

    .purchase-card, .MuiPaper-rounded, .MuiCard-root  {
      min-width: 300px;
      position: absolute;
      top: 30%;
      border-radius: 20px;
      right: calc(30% - 200px);
      min-height: 400px;

      @media #{$mobile} {
        margin-top:10px !important;
        width: 70%;
        top: 100px;
        right: 5%;
        min-width: 90%;
        min-height: 100px !important;
      }

      .MuiCardContent-root {

        
        
      }

      .title {
        font-size: 20px;

        @media #{$mobile} {
          font-size: 18px;
          margin: 5px 0;
        }
      }

      p {
        @media #{$mobile} {
          margin: 5px 0;
        }
      }

      .purchase-data {

        @media #{$mobile} {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .purchase-data-body {

          
          .purchase-kit-image {
            display: flex !important;
            justify-content:center;
            width: 100px !important;
  
            .kit-img {
              width: 100% !important;
            
              @media #{$mobile} {
                display: none;
              }
            }
          }
        }
        
          
        

        .purchase-amount {
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid $grey;

          @media #{$mobile} {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: none;
            width: auto;
            padding-bottom: 0;
          }

          .small-title {
            font-size: 15px;
            color: $grey;

            @media #{$mobile} {
              display: none;
            }
          }

          .primary-link {
            font-size: 1.2em;
          }

          .amount {
            font-size: 42px;
            margin: 8px 0;

            @media #{$mobile} {
              font-size: 22px;
              margin: 0;
              margin-left: 16px;
            }
          }
        }

        .purchase-speech {
          font-size: 16px;
          color: $not-black;
          max-width: 280px;
          margin-top: 32px;

          @media #{$mobile} {
            display: none;
          }
        }
      }
    }
  }
  .payment-right-container {
    background-size: 100% !important;
    padding: 0;
    margin: 0;
  }

  .user-signup-content,
  .company-signup-content {
    width: 100%;
  }
  .your-passwords {
    margin-top: 45px;
    margin-bottom: 12px;

    @media #{$mobile} {
      margin-top: 25px;
    }
  }

  .nutritionist-code {
    color: $not-black;
    width: 100%;
    font-size: 18px;

    @media #{$mobile} {
      width: 100%;
    }

    input {
      text-transform: uppercase;
    }

    .code-clarification {
      font-size: 14px;
    }

    .code-speech {
      margin-top: 56px;
    }
  }
}

.pathologies,
.family-background {
  color: $not-black;

  .checkboxes {
    margin: 32px 0;

    @media #{$mobile} {
      margin-bottom: 0;
    }
  }

  .separator {
    margin: 32px 0;
    border-top: 1px solid $grey;
    width: 100%;

    @media #{$mobile} {
      margin: 16px 0;
    }
  }
}

.mail-sent {
  color: $not-black;

  .huge-title {
    font-size: 30px;
    font-weight: bold;
  }

  .mail-sent-speech {
    font-size: 24px;
    line-height: 50px;

    @media #{$mobile} {
      line-height: 30px;
    }
  }
}

.nutritionist-signup {
  width: 1100px;
  margin: 50px auto;

  @media #{$mobile} {
    max-width: 100%;
    margin: 16px 0;
  }

  .signup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media #{$mobile} {
      max-width: 100%;
      margin: 16px;
    }

    .logo {
      height: 60px;
      width: auto;
      cursor: pointer;

      @media #{$mobile} {
        height: 30px;
      }
    }

    .back {
      margin-top: 30px;
      font-size: 18px;
      color: $primary;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      text-decoration: none;
      display: block;

      @media #{$mobile} {
        margin-top: 0;
      }
    }
  }

  .nutritionist-signup-content {
    margin: 64px 0;
    padding: 72px 32px 32px 32px;
    border-radius: 8px;
    position: relative;

    @media #{$mobile} {
      padding: 0 16px;
      margin: 0;
      box-shadow: none;
    }

    .content-heading {
      position: absolute;
      top: -24px;
      left: 24px;
      width: calc(100% - 148px);
      padding: 32px 48px;
      background-color: $dark-primary;
      border-radius: 24px;
      color: white;

      @media #{$mobile} {
        box-sizing: border-box;
        position: static;
        width: 100%;
        padding: 16px 32px;
        font-size: 14px;
      }

      p {
        margin: 0;
      }

      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }

      .speech {
        font-size: 20px;

        @media #{$mobile} {
          font-size: 18px;
        }
      }
    }
  }

  .nutritionist-data-form,
  .payment-method-form {
    margin: 32px 0 16px 0;
    padding: 0 16px;
    width: 80%;

    @media #{$mobile} {
      box-sizing: border-box;
      width: 100%;
      margin-top: 0;
    }

    .bank-account-details,
    .collection-network-details {
      margin: 64px 0 16px 0;

      @media #{$mobile} {
        margin: 32px 0 16px 0;
      }

      .title {
        margin: 0;
        font-size: 20px;
      }
    }
  }

  .payment-method-form {
    @media #{$mobile} {
      padding: 0;
    }
  }

  .payment-method-details {
    margin: 32px 16px 16px 16px;
    padding: 0 16px;

    @media #{$mobile} {
      margin: 0;
      padding: 0;
    }

    .details-title {
      margin: 64px 0 32px 0;
      font-size: 20px;

      @media #{$mobile} {
        margin: 32px 0 16px 0;
      }
    }

    .grey-container {
      margin-top: 48px;

      @media #{$mobile} {
        margin-top: 24px;
      }
    }
  }

  .nutritionist-mail-sent,
  .nutritionist-verified {
    padding: 96px 32px 64px 32px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;

    @media #{$mobile} {
      padding: 0 16px;
      box-shadow: none;
    }

    .title {
      font-size: 48px;
      font-weight: bold;
      color:black;

      @media #{$mobile} {
        font-size: 24px;
        text-align: center;
      }
    }

    .mail-sent-speech {
      text-align: center;
    }

    .continue-button {
      font-size: 20px;
      margin: 32px 0;

      @media #{$mobile} {
        margin: 16px 0;
        padding: 8px 48px;
      }
    }
  }

  .nutritionist-verified {
    font-size: 20px;
    color: $not-black;

    @media #{$mobile} {
      font-size: 18px;
      text-align: center;
    }

    .nutritionist-code {
      font-size: 48px;
      display: flex;
      padding: 0 64px;
      margin: 24px 0;
      border: 1px solid $grey;
      color: $grey;
      background-color: $light-background;
      border-radius: 6px;

      @media #{$mobile} {
        font-size: 36px;
        padding: 0 48px;
      }
    }

    .dashboard-stuff {
      width: 100%;
      box-sizing: border-box;
      padding: 32px 16px 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media #{$mobile} {
        flex-direction: column;
        padding: 16px 16px 0 16px;
      }

      .chunk {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$mobile} {
          margin: 12px 0;
        }

        img {
          height: 100px;
          width: auto;
          margin-bottom: 16px;

          @media #{$mobile} {
            height: 80px;
            margin-bottom: 8px;
          }
        }

        span {
          color: $not-black;
        }
      }
    }

    .continue-button {
      margin-bottom: 0;
    }
  }

  .continue-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media #{$mobile} {
      padding: 0 16px;
    }
  }
}

h1.huge {
  height: auto;
}

.purchase-card  {
  min-width: 300px;
  position: absolute;
  top: 15%;
  border-radius: 20px;
  right: calc(30% - 130px);
  min-height: 400px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

  @media #{$mobile} {
    margin-top:10px !important;
    width: 70%;
    top: 100px;
    right: 5%;
    min-width: 90%;
    min-height: 100px !important;
    font-size: 0.9em;

  }
  .title {
    font-size: 29px;
    line-height: 34px;
    font-weight: bold;
    color:black;

    @media #{$mobile} {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      height: auto;
    }
  }

  .primary-link, .adn-kit-text, .small-title  {

    @media #{$mobile} {
      //display: flex;
      flex-direction: column;
    }
  }
  .amount {
    padding: 0px;
    margin: 0px;
  }
  .purchase-speech {
    padding: 0px;
    margin: 0px;

  }


  .purchase-data {

    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .purchase-data-body {

      @media #{$mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
      .purchase-kit-image {
        display: flex !important;
        justify-content:center;
        width: 100% !important;

        .kit-img {
          width: 275px !important;
        
          @media #{$mobile} {
            display: none;
          }
        }
      }
    }
    
      
    

    .purchase-amount {
      width: 100%;
      padding-bottom: 4px;
      //border-bottom: 1px solid $grey;

      @media #{$mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: none;
        width: auto;
        padding-bottom: 0;
      }

      .small-title {
        font-size: 18px;
        color: $grey;
        font-weight: normal;

        @media #{$mobile} {
          //display: none;
        }
      }

      .primary-link {
        color: black;
        font-size: 1.5em;
      }

      .amount {
        font-size: 39px;
        margin: 8px 0;

        @media #{$mobile} {
          font-size: 22px;
          margin: 0;
          margin-left: 16px;
        }
      }
    }

    .purchase-speech {
      font-size: 17px;
      color: black;
      font-weight: lighter;
      max-width: 280px;
      //margin-top: 32px;
      padding: 0px;
      padding-top: 4px;

      @media #{$mobile} {
        display: none;
      }

      p {
        font-weight: lighter;
      }
    }
  }

}