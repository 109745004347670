.auth-partition-container {
  color: $primary-text;
  display: flex;
  height: 100%;

  @media #{$mobile} {
    flex-direction: column;
  }
}

.auth-partition {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;

  @media #{$mobile} {
    align-items: start;
  }
}

.auth-partition-with-img {
  @extend .auth-partition;

  max-width: 46%;
  background-repeat: no-repeat;
  background-color: $dark-primary;
  display: flex;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;

  @media #{$mobile} {
    max-height: 100px;
    max-width: 100%;
    flex-direction: row-reverse;
    align-items: center;
  }

  .auth-mosaic {
    height: 100%;
    margin-right: 60px;
    @media #{$mobile} {
      margin-right: 0px;
      content: url(../assets/auth-left-mosaic-mobile.png);
    }
  }

  .content {
    width: 43%;
    color: $white;

    @media #{$mobile} {
      width: 100%;
      h1,
      p,
      a {
        display: none;
      }
      display: flex;
      align-items: center;
    }

    .auth-info-title {
      font-size: 42px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal;
    }

    p {
      text-align: justify;
    }

    a {
      color: $pink;
      text-decoration: none;
      cursor: pointer;

      p {
        margin-block-start: 3em;
      }
    }

    .logo {
      width: 50%;
      margin-bottom: 3em;
      cursor: pointer;

      @media #{$mobile} {
        margin-left: 25px;
        margin-bottom: 0;
      }
    }
  }
}

.auth-partition-no-img {
  width: 100%;
  margin-left: 15%;
  margin-right: 25%;
  display: flex;
  flex-direction: column;

  font-size: 16px;

  @media #{$mobile} {
    margin: 0 5%;
    flex: 1;
    height: 100%;
  }

  h2 {
    font-size: 18px;
  }

  .sub-heading {
    color: $light-secondary-text;
    margin-block-start: 0;
  }

  .MuiTextField-root {
    margin-top: 0.8em;
  }
  .auth-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .auth-back {
      display: flex;
      width: fit-content;
      align-items: center;
      color: $primary;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      p {
        margin-left: 0.8em;
      }
    }
  }

  .register-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    button {
      width: 100%;
    }
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin: 4em 0;

  @media #{$mobile} {
    flex-direction: column;
    margin: 1em 0;

    button {
      margin-top: 2em;
    }
  }
}

/*
.dialogCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .dialog {
    text-align: center;
    width: 800px !important;
    color:red !important;
    height: 400px;
    border-radius: 19px;
    font-size: 2em;
  
    @media #{$mobile} {
      width: 90%;
      height: auto;
      font-size: 1em;
    }

    .dialogContent {
      width: 800px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 60px;
      color:red !important;
    
      @media #{$mobile} {
        padding: 10px;
      }

      .spinner {
        margin-top: 20px;
      
        @media #{$mobile} {
          margin-top: 10px;
        }
      }
    }
  }
}
*/