.arrow-steps {
  margin: 48px 0;

  @media #{$mobile} {
    max-width: 100%;
    margin: 16px 0;
  }

  .steps {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$mobile} {
      padding: 0 16px;
    }

    .parent {
      display: block;
      position: relative;
      margin-left: -3px;

      .arrow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $pastel-primary;
        border-color: $pastel-primary;
        height: 58px;
        width: 200px;
        border-radius: 10px 0 0 10px;
        color: white;

        @media #{$mobile} {
          height: 42px;
          width: 50px;
        }

        &::after {
          content: "";
          height: 44px;
          width: 43px;
          background-color: inherit;
          border: inherit;
          position: absolute;
          left: 178px;
          top: 7px;
          clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
          transform: rotate(-135deg);
          border-radius: 0.26em 0.26em 0.26em 0.35em;

          @media #{$mobile} {
            height: 32px;
            width: 31px;
            left: 34px;
            top: 5px;
          }
        }

        .content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 75%;
          margin-left: 10%;
          font-size: 18px;

          @media #{$mobile} {
            width: 100%;
            padding: 0 16px 0 32px;
            font-size: 14px;
            margin-left: 0;
          }

          .number {
            font-size: 20px;
            margin-right: 32px;

            @media #{$mobile} {
              font-size: 16px;
              margin-right: 16px;
            }
          }

          .title {
            font-size: 12px;

            @media #{$mobile} {
              display: none;
            }
          }

          .name {
            line-height: 20px;

            @media #{$mobile} {
              line-height: 16px;
            }
          }

          p {
            margin: 0;
          }
        }

        .name-desktop {
          @media #{$mobile} {
            display: none;
          }
        }

        .number-mobile {
          display: none;

          @media #{$mobile} {
            display: block;
            margin-left: 24px;
          }
        }
      }

      .past-arrow {
        background-color: $light-pastel-primary;
        border: 1px solid white;

        .arrow-details {
          @media #{$mobile} {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          svg {
            display: none;

            @media #{$mobile} {
              display: block;
              margin-left: 4px;
            }
          }
        }
      }

      .present-arrow {
        height: 70px;

        @media #{$mobile} {
          height: 50px;
          width: 170px;
        }

        &::after {
          height: 52px;
          width: 52px;
          left: 173px;
          top: 9px;

          @media #{$mobile} {
            height: 38px;
            width: 37px;
            left: 150px;
            top: 6px;
          }
        }
      }

      .future-arrow {
        background-color: white;
        color: #cacaca;
        border: 1px solid #dedede;
      }
    }
  }
}

.grey-container {
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: $light-grey;
  padding: 5px 15px;
  color: $grey;
}

.yellow-container {
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: $warning;
  padding: 5px 15px;
  color: $not-black;
}

.warning-container {
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: $warning;
  padding: 24px 16px;
  color: $dark-secondary-text;

  span {
    margin-left: 16px;
  }
}

.primary-link {
  color: $primary;
  cursor: pointer;
  user-select: none;
}

.loading-full-screen,
.error-animation {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-animation {
  flex-direction: column;
}

.button-link {
  background: none;
  border: none;
  color: $primary;
  padding: initial;

  &:active {
  }
}

.error-message {
  color: $filter-red;
  font-size: 14px;
}
.tyc-container {
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: $light-grey;
  padding: 5px 15px;
  color: $grey;
  height: 250px;
  overflow: auto;
  max-height: 250px;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  width: 38px !important;
  height: 38px !important;
  color: white;
}

@media screen and (max-width: 767px) {
  .whatsapp_float {
    width: 55px;
    height: 55px;
    bottom: 20px;
    right: 10px;
    font-size: 30px;
    box-shadow: none;
  }
}

.tyc {

  
  .signup-left-content {

    @media #{$mobile}{
      
      margin-top: 0px !important;
    }

    .header {
      
      
      @media #{$mobile}{
        padding: 0px;
      }
      img {
        
        @media #{$mobile} {
          display:flex;
          order:1;
          padding-left: 20px;
        }
      }
      a {
        
        @media #{$mobile} {
          display:flex;
          order:2;
          padding-right: 20px;
        }
      }

    }
    .terms-cond {
      

      @media #{$mobile} {
        padding: 20px 20px;
        
      }
      h1 {
        height: auto;
      }
      p {
        padding-bottom: 50px;   

        @media #{$mobile} {
          padding-bottom: 30px;
          
        }
      }
      
      
    }

  }
}
.terms-cond {
  padding-top: 50px;
}