$landing-side-padding: 15%;
$landing-side-padding-mobile: 20px;
$medium-large: "screen and (min-width: 1500px) and (max-width: 1880px)";
$medium: "screen and (min-width: 1285px) and (max-width: 1550px)";
$mobile-medium: "screen and (min-width: 769px) and (max-width: 1290px)";

/*$not-black: #545454;*/

.landing {
  height: 100%;
  display: flex;
  flex-direction: column;

  .landing-square-image {
    height: 441px;
    width: 403px;
    background-size: cover;
    border-radius: 135px;
    background-position: center;
    @media #{$mobile} {
      width: 330px;
      height: 290px;
      border-radius: 80px;
    }
  }

  .landing-title {
    color: $greyish-brown;
    font-weight: bold;
  }

  .landing-p {
    color: $dark-secondary-text;
    font-size: 24px;
    @media #{$mobile} {
      font-size: 20px;
    }
  }

  .landing-header {
    padding: 30px $landing-side-padding;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mobile} {
      padding: 16px;
      width: 100%;
      box-sizing: border-box;
      z-index: 2000;
      position: relative;
    }

    .landing-header-img {
      height: 40px;
      margin-right: 20px;
      cursor: pointer;

      @media #{$mobile} {
        height: 30px;
      }
    }

    .landing-header-burger {
      display: none;
      @media #{$mobile} {
        display: flex;
      }
    }
  }

  .landing-header-options-desktop {
    @media #{$mobile} {
      display: none;
    }
  }
  .cover {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1634906803/mistergen/bg-cover_nzd6bq.png")
      no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    overflow: hidden;
    
    @media #{$mobile} {
      flex-direction: column;
      max-height: none;
    }
    .cover-texts {
      width: 30%;
      padding-left: $landing-side-padding;
      height: auto;
      min-width: 375px;
      padding-bottom: 50px;
      order: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      

      @media #{$mobile} {
        box-sizing: border-box;
        width: inherit;
        padding-bottom: 20px;
        padding: 20px $landing-side-padding-mobile;
        
      }
      @media #{$medium-large} {
      }
      #cover-texts-h {
        font-size: 70px;
        font-weight: bold;
        margin-bottom: 40px;
        padding-top: 40px;
        height: auto;
        @media #{$mobile} {
          width: 100%;
          font-size: 38px;
          font-weight: bold;
          margin-bottom: 10px;
          padding-left: $landing-side-padding-mobile;
        }
        @media #{$medium-large} {
          font-size: 60px;
        }
        @media #{$medium} {
          font-size: 55px;
        }
        @media #{$mobile-medium} {
          font-size: 47px;
        }
      }
      #cover-texts-p {
        font-size: 22px;
        padding-bottom: 40px;
        @media #{$mobile} {
          display: none;
        }
        @media #{$medium} {
          font-size: 21px;
        }
        @media #{$mobile-medium} {
          font-size: 17px;
        }
      }
      .cover-texts-button {
        display: flex;
        width: fit-content;
        flex-direction: column;

        @media #{$mobile} {
          width: 100%;
          padding-bottom: 30px;
          //padding-left: $landing-side-padding-mobile;
          height: fit-content;
        }

        Button {
          margin:10px 0 ;
          
          @media #{$mobile} {
            width: 100%;
          }
        }
      }
    }
    .cover-recomendations-girl {
      display: flex;
      width: 55%;
      height: auto;
      overflow: hidden;
      order: 2;
      min-width: 375px;
      @media #{$mobile} {
        width: 100%;
      }
      @media #{$mobile-medium} {
        overflow: visible;
      }

      .cover-rec {
        width: 33%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        max-width: 223px;

        @media #{$mobile} {
          justify-content: flex-end;
        }

        img {
          height: auto;

          @media #{$mobile} {
            width: 160px;
            height: auto;
            position: relative;
            bottom: -11px;
          }
          @media #{$mobile-medium} {
            width: 200px;
            height: auto;
            position: relative;
            bottom: -31px;
          }
        }
      }

      .cover-girl {
        overflow: hidden;
        width: 67%;
        display: flex;
        justify-content: flex-start;
        max-height: 624px;
        @media #{$medium-large} {
          overflow: visible;
        }
        @media #{$medium} {
          overflow: visible;
        }
        @media #{$mobile-medium} {
          overflow: visible;
        }
        img {
          position: relative;
          left: -100px;
          height: auto;

          @media #{$mobile} {
            width: border-box;
            left: -130px;
            height: auto;
            max-height: 450px;
          }
        }

        .indicators {
          overflow: hidden;

          .items {
            width: border-box;
            position: absolute;

            .itemsrel {
              position: relative;

              width: 750px;
              height: 600px;
              top: 0px;
              left: 0px;
              color: white;
              font-size: 15px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              z-index: 2;

              @media #{$mobile} {
                max-height: 450px;
                width: auto;
                justify-content: space-around;
              }
              @media #{$medium-large} {
                width: 650px;
              }
              @media #{$medium} {
                width: 480px;
              }
              @media #{$mobile-medium} {
                width: 340px;
              }
              div {
                width: 130px;
                height: 30px;
                padding-left: 20px;
                border-radius: 15px;
                display: flex;
                justify-content: space-between;

                strong {
                  padding-top: 3px;
                }
                span {
                  border-radius: 100%;
                  padding: 5px;
                }
              }

              .item1 {
                background-color: #f46b64;
                width: 100px;
                position: relative;
                right: -450px;

                @media #{$mobile} {
                  margin-left: 40px;
                  margin-bottom: 150px;
                  right: 0;
                  top: -10px;
                }
                @media #{$medium} {
                  right: -380px;
                }
                @media #{$mobile-medium} {
                  right: -240px;
                }
                span {
                  background-color: #d85652;
                }
              }
              .item2 {
                background-color: #6964ab;
                position: relative;
                margin-top: 40px;
                margin-left: 30px;

                @media #{$mobile} {
                  margin-left: 90px;
                }

                span {
                  background-color: #3b3b82;
                }
              }
              .item3 {
                background-color: #ffb048;
                width: 150px;
                position: relative;
                right: -550px;

                @media #{$mobile} {
                  //display: none;
                  right: 0px;
                }
                @media #{$medium-large} {
                  right: -450px;
                }
                @media #{$medium} {
                  right: -330px;
                }
                @media #{$mobile-medium} {
                  right: -240px;
                }
                span {
                  background-color: #d38931;
                }
              }
            }
          }
        }
      }
    }
  }
  .landing-intro {
    display: flex;
    padding: 70px 0;

    @media #{$mobile} {
      flex-direction: column;
    }
    .landing-intro-texts {
      width: 60%;
      margin-left: $landing-side-padding;
      @media #{$mobile} {
        width: inherit;
        margin: 0 $landing-side-padding-mobile;
      }

      #landing-intro-h {
        font-size: 37px;
        font-weight: 800;
        margin-bottom: 30px;
        height: auto;
        @media #{$mobile} {
          font-size: 31px;
          font-weight: bold;
        }
        @media #{$medium} {
          font-size: 38px;
          font-weight: bold;
        }
        @media #{$mobile-medium} {
          font-size: 33px;
        }
      }
      #landing-intro-p {
        font-size: 1.3em;
        @media #{$mobile} {
          margin-bottom: $landing-side-padding-mobile;
          font-size: 1.1em;
          padding-bottom: 20px;
        }
      }
    }
    .landing-intro-action {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 50px;

      @media #{$mobile} {
        margin: 0 $landing-side-padding-mobile;
        width: inherit;
        button {
          width: 100%;
        }
      }

      .landing-intro-decoration {
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        @media #{$mobile} {
          display: none;
        }
        .landing-intro-circle-small {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: $vibrant-yellow;

          position: relative;
          top: 84px;
        }

        .landing-intro-circle-big {
          width: 168px;
          height: 168px;
          border-radius: 50%;
          background-color: $vibrant-yellow;
          position: relative;
          right: -84px;
        }

        .landing-intro-button {
          display: flex;
          width: fit-content;
          @media #{$mobile} {
            width: 100%;
            height: fit-content;
          }
        }
      }
    }
  }
  
  .landing-intro-prep-meals {
    width: 100%;
    //height: 719px;
    height: auto;
    background-color: #D9d9d9;
    background-image: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1694195245/landing-meals-bg_pu1ebf.svg");
    background-position: right top;
    background-size: cover; 
    background-position-x: -250px;
    display: flex;

    @media #{$medium-large} {
      background-position-x: 0px;
    }
    @media #{$medium} {
      background-position-x: -50px;
    }
    @media screen and (min-width: 1881px) {
      background-position-x: 0px;
    }
    @media #{$mobile-medium} {
      background-image:none;
    }
    @media #{$mobile} {
      flex-direction: column;
      background-image:none;

    }

    .prep-meals-text {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px;
      box-sizing: border-box;

      @media #{$mobile-medium} {
        padding: 50px;
      } 
      @media #{$mobile} {
        width: 100%;
        padding: 45px 10px 25px 10px;
      } 


      h1, h2, p {

        width: 80%;
        height: auto;
        text-align: left;

        @media #{$mobile} {
          width: 100%;
          text-align: center;
        } 
      }
      h1 {
        font-size: 48px;

        @media #{$mobile-medium} {
          font-size: 2.5em;
        } 
        @media #{$mobile} {
          font-size: 2em;
          
        }
      }
      h2 {
        font-weight: lighter;
        font-size: 36px;
        
        @media #{$mobile-medium} {
          font-size: 1.8em;
        }
        @media #{$mobile} {
          font-size: 1.4em;
        }
      }
      p {
        padding-top: 30px;
        font-size: 1.2em;

        @media #{$mobile-medium} {
          font-size: 1em;
        }
        @media #{$mobile} {
          font-size: 1em;
          font-weight: lighter ;
        }
      }
      .cover-texts-button {
        display: flex;
        width: 80%;
        padding-top: 40px;
        box-sizing: border-box;

        @media #{$mobile} {
          width: 100%;
          padding: 0px 10px 20px 10px;
          //padding-left: $landing-side-padding-mobile;
          
        }

        Button {
          margin:10px 0 ;
          width: 50%;
          z-index: 1000;
          
          @media #{$mobile} {
            width: 100%;
          }
        }
      }
    }
    .prep-meals-img {
      width: 40%;

      @media #{$mobile} {
        width: 100%;
      }
      
/*
  $medium-large: "screen and (min-width: 1500px) and (max-width: 1880px)";
  $medium: "screen and (min-width: 1285px) and (max-width: 1550px)";
  $mobile-medium: "screen and (min-width: 769px) and (max-width: 1290px)";
  */
      .rel-div {
        position: relative;

        @media #{$mobile} {
          height: 300px;
        }
        
        .abs-div {
          position: absolute;
          bottom: -770px;
          left: -430px;

            @media #{$medium} {
              bottom: -670px;
              left: -350px;
            }
            @media #{$mobile-medium} {
              bottom: -540px;
              left: -160px;
            }
            @media #{$mobile} {
              position: relative;
              display: flex;
              justify-content: center;
              bottom: 25px;
              left: 0px;
            }
          
          img {
            width: 981px;
            height: 743px;
            
            @media #{$medium} {
              width: 800px;
              height: 620px;
            }
            @media #{$mobile-medium} {
              width: 600px;
              height: 500px;
            }
            @media #{$mobile} {
              width: 95%;
              height: auto;
            }

          }
        }
        
      }
      
    }
  }
  .landing-intro-banner {
    height: 339px;
    width: 100%;
    background-image: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-intro-banner_mel3yx.png");
    background-size: cover;
    background-position: center;
    //margin-top: 90px;

    @media #{$mobile} {
      //margin-top: 40px;
      height: 375px;
    }
  }

  .landing-aboutus {
    padding-top: 240px;
    @media #{$mobile} {
      padding: 49px 0 0 0;
    }
    .landing-aboutus-texts {
      display: flex;
      padding: 0 $landing-side-padding;
      @media #{$mobile} {
        flex-direction: column;
        padding: 0 $landing-side-padding-mobile;
      }
      #landing-aboutus-h {
        font-size: 32px;
        width: 320px;
        @media #{$mobile} {
          margin-bottom: 1em;
        }
      }
      #landing-aboutus-p {
        p {
          margin-block-start: 0;
          margin-block-end: 0;
        }
        p:nth-child(1) {
          margin-bottom: 1em;
        }
      }
    }
    #landing-aboutus-oval {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -50px;
      @media #{$mobile} {
        top: 0;
      }
      img {
        height: 364px;
        width: 161px;
        @media #{$mobile} {
          height: 186px;
          width: 66px;
        }
      }
    }
    .landing-aboutus-images {
      display: flex;
      justify-content: center;
      @media #{$mobile} {
        flex-direction: column;
        align-items: center;
        margin: 0 $landing-side-padding-mobile;
      }
      img {
        height: 428px;
        width: 486px;
        object-fit: cover;
        border-radius: 110px;
        @media #{$mobile} {
          width: 330px;
          height: 290px;
          border-radius: 80px;
        }
      }
      #img-1 {
        object-position: 30% 0%;
      }
      #img-2 {
        object-position: 100% 20%;
      }
      #landing-aboutus-image1 {
        display: grid;
        position: relative;
        top: -214px;
        margin-right: 14px;
        @media #{$mobile} {
          margin: 0;
          top: -124px;
        }

        #landing-aboutus-donut {
          width: 107px;
          height: 107px;
          object-fit: contain;
          border: solid 40px $primary;
          border-radius: 50%;
          position: absolute;
          top: 334px;
          left: 100px;
          z-index: 1;
          @media #{$mobile} {
            width: 72px;
            height: 72px;
            top: 207px;
            left: 50px;
            border-width: 26px;
          }
        }
        img {
          z-index: 2;
        }
      }
      img:nth-child(2) {
        margin-left: 14px;
        @media #{$mobile} {
          margin: 0;
          position: relative;
          top: -50px;
        }
      }
    }
  }

  .landing-how {
    #landing-rogue-circle {
      width: 168px;
      height: 168px;
      background-color: $vibrant-yellow;
      border-radius: 50%;
      position: relative;
      left: -84px;
      bottom: -50px;

      @media #{$mobile} {
        margin-top: 0;
        width: 77px;
        height: 77px;
        left: -39px;
      }
    }

    .landing-how-texts {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 0 $landing-side-padding;
      position: relative;
      top: -50px;

      @media #{$mobile} {
        flex-direction: column;
        top: 0;
      }

      h2 {
        font-size: 122px;
        margin-right: 10%;
        @media #{$mobile} {
          font-size: 56px;
        }
      }
    }
    .landing-how-steps {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 0 15%;
      margin-top: 40px;
      @media #{$mobile} {
        display: none;
        margin-top: 0;
        padding: 0 $landing-side-padding-mobile;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .landing-how-steps-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 200px;
        .landing-how-steps-img {
          height: 140px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          img {
            width: 112px;
          }
        }
        .landing-how-steps-no {
          background-color: $pastel-primary;
          border-radius: 50%;
          color: white;
          font-size: 12px;
          height: 1.5em;
          width: 1.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1em;
          padding: 0.5em;
        }
        .landing-how-steps-text {
          color: $pastel-primary;
          font-weight: bold;
          text-align: center;
        }
      }
    }

    .steps-explained {
      display: flex;
      flex-direction: column;

      @media #{$mobile} {
        padding-bottom: 20px;
      }

      .steps-items {
        margin-left: $landing-side-padding;
        margin-right: $landing-side-padding;
        display: grid;
        gap: 20px;
        padding: 40px 10px;
        border-bottom: 1px solid #b6b6b6;

        @media #{$mobile} {
          height: auto;
          margin: 10px $landing-side-padding-mobile;
          grid-template-columns: repeat(2, 1fr) !important;
          grid-template-rows: repeat(3, auto) !important;
          grid-template-areas:
            "stepTitle stepTitle"
            "stepImg stepImg"
            "stepText stepText" !important;
        }

        .stepImg {
          grid-area: stepImg;
          display: flex;
          align-items: center;
          @media #{$mobile} {
            gap: 0;
            width: 100%;
          }
          @media #{$medium} {
            gap: 0;
            width: 80%;
          }
        }
        .stepTitle {
          grid-area: stepTitle;

          display: flex;
          flex-direction: row;
          align-items: flex-end;

          @media #{$mobile} {
            font-size: 30px;
            justify-content: center;
          }

          h1 {
            font-weight: bold;
            font-size: 120px;
            line-height: 110px;
            display: flex;
            align-items: flex-end;

            @media #{$mobile} {
              font-size: 85px;
              line-height: 70px;
            }
            @media #{$medium} {
              font-size: 75px;
              line-height: 65px;
            }
          }
          h2 {
            width: 330px;
            padding-left: 10px;
            font-weight: 800;
            line-height: 60px;
            font-size: 40px;
            display: flex;
            align-items: flex-end;

            @media #{$mobile} {
              font-size: 30px;
              line-height: 33px;
            }
            @media #{$medium} {
              font-size: 29px;
              line-height: 33px;
            }
          }
        }
        .stepText {
          grid-area: stepText;
          color: #707070;
          font-size: 29px;

          @media #{$mobile} {
            font-size: 20px;
            text-align: center;
          }
          @media #{$medium} {
            font-size: 19px;
          }
        }
      }

      .step-one {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          "stepTitle stepImg"
          "stepText stepImg";

        .stepTitle {
          color: #8bc6ae;
        }
      }
      .step-two {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          "stepImg stepTitle"
          "stepImg stepText";

        .stepImg {
          padding-top: 30px;
        }

        .stepTitle {
          color: #ffb048;
        }
      }

      .step-three {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          "stepTitle stepImg"
          "stepText stepImg";

        .stepTitle {
          color: #856a8f;
        }
      }
      .step-four {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          "stepImg stepTitle"
          "stepImg stepText";
        border-bottom: none;

        .stepTitle {
          color: #f46b64;
        }
      }
    }
  }
  .faq {
    @extend .landing-how;

    .landing-faq-title {
      @extend .landing-title;

      display: flex;
      justify-content: center;
      padding-top: 70px;

      @media #{$mobile} {
        padding-top: 25px;
      }

    }
    .faq-title {

      @media #{$mobile} {
        font-size: 2.2em;
      }
    }

    .landing-faq-content {

      display: flex;
      justify-content: center;

      .faq-acordeon {
        
        width: 70%;
        margin-top: 60px;
        border-radius: 23px;
        overflow: hidden;

        @media #{$mobile} {
          width: 90%;
        }

        .question {
          width: 100%;
          color: $primary-text;
          margin: 15px 0;
          border-radius: 15px;
          overflow: hidden;

          h2 {
            margin: 0;
            padding: 1rem;
            background-color: #eee;
            cursor: pointer;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;

            @media #{$mobile} {
              font-size: 1rem;
              font-weight: normal;
            }
          }
          .active {
            color: $primary;
          }
        
        }
        .answer {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;
          p {
            margin: 0;
            padding: 2.6rem;
            background-color: #f5f5f5;
            font-size: 1.1rem;
            line-height: 1.5;
            border-bottom:7px solid $dark-pastel-primary;

            @media #{$mobile} {
              padding: 1.4rem;
              font-size: 0.9rem;
              font-weight: lighter;
            }
            
          }
          &.activo {
            max-height: 1000px;
            transition: max-height 0.8s ease-in-out;
            overflow: visible;
            h2 {
              color: $primary;
            }
          }
          
        }
        
      }
      
    }
  }
  .results {
    display: flex;
    flex-direction: row;
    background-color: #856a8f;

    @media #{$mobile} {
      flex-wrap: wrap;
      padding: 20px 0;
    }

    .results-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media #{$mobile} {
        display: none;
      }
      div {
        padding-top: 80px;
      }
      img {
        width: 100%;
      }
      .results-iso-logo {
        z-index: 1000;
        
        @media #{$mobile} {
          display: none;
        }

        div {
          width: 80px;
          top: 60px;
          left: 430px;

          @media #{$medium} {
            left: 360px;
          }

          img {
            width: 80%;
          }
        }
      }
    }
    .results-texts {
      width: 50%;
      display: flex;
      flex-direction: column;
      color: white;
      margin-right: $landing-side-padding;
      padding-left: 20px;

      @media #{$mobile} {
        margin: 0;
        margin: 0 $landing-side-padding-mobile;
        justify-content: center;
        width: 100%;
      }
      .results-title {
        padding-top: 40px;
        font-size: 69px;
        line-height: 70px;
        font-weight: bold;
        height: fit-content;

        @media #{$mobile} {
          font-size: 36px;
          line-height: 44px;
        }
        @media #{$medium} {
          font-size: 50px;
          line-height: 51px;
        }
      }
      .results-intro {
        font-size: 20px;
        font-weight: normal;

        @media #{$mobile} {
          font-size: 19px;
        }
        @media #{$medium} {
          font-size: 18px;
        }
        strong {
          font-size: 25px;

          @media #{$mobile} {
            font-size: 19px;
          }
          @media #{$medium} {
            font-size: 22px;
          }
        }
      }
      .results-specs {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 50px;
        font-size: 18px;
        padding-bottom: 40px;

        @media #{$mobile} {
          font-size: 19px;
        }
        @media #{$medium} {
          font-size: 15px;
        }

        div {
          width: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 10px;

          @media #{$mobile} {
            padding-left: 20px;
          }
        }

        li {
          margin-block-start: 0px;
          list-style: none;
          text-transform: uppercase;

          @media #{$mobile} {
            line-height: 44px;
          }
        }
      }
    }

    .iso-logo-mobile {
      display: none;

      @media #{$mobile} {
        display: flex;
        width: 44px;
        height: 29px;
        padding-bottom: 10px;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
  }
  .landing-buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 140px;
    @media #{$mobile} {
      margin-top: 40px;
    }
    .landing-buy-main {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $landing-side-padding;
      @media #{$mobile} {
        flex-direction: column;
        padding: 0 $landing-side-padding-mobile;
      }
      .landing-buy-info {
        .landing-buy-texts {
          @media #{$mobile} {
            text-align: center;
          }
          #landing-buy-title {
            font-size: 32px;
          }
          #landing-buy-p {
            margin: 1em 0;
          }
        }
        button {
          margin-top: 2em;
          @media #{$mobile} {
            margin-top: 1em;
            margin-bottom: 4em;
            width: 100%;
          }
        }
      }
      img {
        height: 428px;
        width: 486px;
        margin-left: 85px;
        border-radius: 110px;
        object-fit: cover;
        @media #{$mobile} {
          height: 290px;
          width: 330px;
          margin-left: 0;
          border-radius: 80px;
        }
      }
    }
    .landing-buy-banner {
      height: 462px;
      width: 90%;
      background-image: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1634906805/mistergen/landing-buy-banner_zdv6nh.png");
      background-size: cover;
      background-position: center;
      border-radius: 35px;
      overflow: hidden;
      margin-top: 190px;
      @media #{$mobile} {
        display: none;
      }
    }
  }

  .landing-nutritionists {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 $landing-side-padding;
    margin-top: 115px;
    @media #{$mobile} {
      padding: 0 $landing-side-padding-mobile;
      margin-top: 80px;
    }
    #landing-nutritionists-h {
      font-size: 122px;
      line-height: 0.93;
      margin-bottom: 28px;
      margin-right: 200px;
      @media #{$mobile} {
        font-size: 56px;
        line-height: 1.14;
        margin-right: 0;
      }
    }
    .landing-nutritionists-body {
      display: flex;
      align-items: flex-end;
      @media #{$mobile} {
        flex-direction: column;
        align-items: center;
      }
      .landing-nutritionists-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 85px;

        @media #{$mobile} {
          margin-right: 0;
        }
        p {
          margin-bottom: 1em;
        }
        p:last-of-type {
          font-weight: bold;
        }
        #landing-nutritionists-button-desktop {
          width: fit-content;
          @media #{$mobile} {
            display: none;
          }
        }
      }
      .landing-nutritionists-decoration {
        position: relative;
        #landing-nutritionists-image {
          background-image: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-nutritionists-image_ltu9y3.png");
          margin-top: 33px;
        }
        #landing-nutritionists-circle {
          height: 168px;
          width: 168px;
          border-radius: 50%;
          background-color: $vibrant-yellow;
          position: absolute;
          bottom: 357px;
          left: 255px;
          @media #{$mobile} {
            height: 84px;
            width: 84px;
            z-index: -1;
            bottom: 248px;
            left: 246px;
          }
        }
      }

      #landing-nutritionists-button-mobile {
        display: none;
        width: 100%;
        @media #{$mobile} {
          display: inherit;
          margin-top: 61px;
        }
      }
    }
  }

  .landing-businesses {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 $landing-side-padding;
    margin-top: 183px;
    @media #{$mobile} {
      padding: 0 $landing-side-padding-mobile;
      margin-top: 83px;
    }
    #landing-businesses-h {
      font-size: 122px;
      line-height: 0.93;
      width: 684px;
      text-align: justify;
      position: relative;
      top: 50px;
      z-index: 2;
      @media #{$mobile} {
        font-size: 56px;
        line-height: 1.14;
        position: static;
        width: auto;
        text-align: left;
      }
    }
    .landing-businesses-body {
      display: flex;
      align-items: center;
      @media #{$mobile} {
        flex-direction: column-reverse;
      }
      #landing-business-button-mobile {
        display: none;
        @media #{$mobile} {
          display: inherit;
          width: 100%;
          margin-top: 45px;
        }
      }
      .landing-businesses-decoration {
        position: relative;
        #landing-businesses-image {
          background-image: url("https://res.cloudinary.com/hqs-cdn/image/upload/v1634906805/mistergen/landing-businesses-image_x02cw1.png");
          @media #{$mobile} {
            margin-top: 40px;
          }
        }
        #landing-businesses-donut {
          width: 131px;
          height: 131px;
          border: solid 40px $vibrant-orange;
          border-radius: 50%;
          position: absolute;
          bottom: -65px;
          left: -30px;
          z-index: -1;
          @media #{$mobile} {
            width: 66px;
            height: 66px;
            border-width: 20px;
            bottom: -30px;
            left: -10px;
          }
        }
      }
      .landing-businesses-info {
        margin-left: 10%;
        @media #{$mobile} {
          margin-left: 0;
          margin-top: 27px;
        }

        #landing-business-button-desktop {
          margin-top: 45px;
          @media #{$mobile} {
            display: none;
          }
        }
      }
    }
  }

  .landing-cat {
    width: 100%;
    background-color: $dark-pastel-primary;
    display: flex;
    margin-top: 260px;
    @media #{$mobile} {
      margin-top: 90px;
      height: 520px;
    }
    .landing-cat-info {
      padding: 100px 10% 100px 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media #{$mobile} {
        padding: 51px $landing-side-padding-mobile;
        padding-bottom: 0;
        text-align: center;
      }
      #landing-cat-h {
        font-size: 94px;
        font-weight: bold;
        color: $light-background;
        height: fit-content;

        @media #{$mobile} {
          font-size: 42px;
        }
      }
      #landing-cat-p {
        color: $white;
        opacity: 0.5;
      }
      button {
        width: fit-content;
        @media #{$mobile} {
          width: 100%;
        }
      }
    }
    img {
      height: 752px;
      width: 384px;
      @media #{$mobile} {
        display: none;
      }
    }
  }

  .landing-footer-desktop {
    width: 100%;
    padding: 48px $landing-side-padding;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $darker-primary;
    @media #{$mobile} {
      display: none;
    }
    .landing-footer-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .landing-footer-img {
        height: 40px;
        margin-right: 20px;
      }
      .landing-footer-options {
        color: $primary;
        display: grid;
        grid-template-columns: auto auto auto auto;
        column-gap: 30px;
        align-items: center;
        p {
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .landing-footer-copyright {
      color: $dark-primary;
      font-size: 12px;
      line-height: 2.7;
    }
  }
  .landing-footer-mobile {
    display: none;
    background-color: $dark-pastel-primary;
    margin: 0;
    @media #{$mobile} {
      display: flex;
      flex-direction: column;
    }
    p {
      color:white;
      cursor: pointer;
      font-size:16px;
      font-weight:bold;
      text-align:center;
    }
    .landing-footer-copyright {
      color: $white;
      font-size: 12px;
      line-height: 2.7;
      text-align: center;
      padding: 1.5em 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.landing-drawer-paper {
  border-radius: 0 0 15px 15px;
  padding: 1em;
  top: 60px !important;
  .landing-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .landing-header-img {
      height: 30px;
    }
  }
}
.landing-header-options-mobile {
  display: none;
  @media #{$mobile} {
    display: flex;
  }
}
.landing-header-options {
  color: $primary;
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 30px;
  align-items: center;
  @media #{$mobile} {
    display: flex;
    flex-direction: column;
  }
  p {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    @media #{$mobile} {
      font-size: 24px;
    }
  }
  button {
    @media #{$mobile} {
      width: 100%;
      margin: 1em;
    }
  }
}
