@import "./variables";
@import "./auth";
@import "./common";
@import "./dashboard";
@import "./userDashboard";
@import "./forms";
@import "./header";
@import "./landing";
@import "./payment";
@import "./profile";
@import "./signup";
@import "./verify";
@import "./endUser";
@import "./menuSemanal";

@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

html,
body,
#root,
h1,
h2,
h3,
h4 {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Mukta", sans-serif;
}

.main {
  height: 100%;
}

.main-logged {
  background-color: $light-background;
}

.link-al-pdf {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: #7c67e2;
  font-size: 1rem;
  text-decoration: none;
  margin-right: 10px; 

  @media #{$mobile}{  
    margin-right: 30px !important;
  }


  .pdf-link {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: #7c67e2;
    font-size: 1rem;
    text-decoration: none;
    margin-right: 10px;

    @media #{$mobile}{  
      margin-right: 30px;
    }
  }
}